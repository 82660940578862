/* eslint-disable no-unused-vars */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { MenuShortCut } from '../../../store/types'

/**
 * Tipos botones admitidos en el menú global
 */
export enum ButtonTypes {
  none = 'none',
  new = 'Nuevo',
  save = 'Guardar',
  edit = 'Editar',
  undo = 'Deshacer',
  discount = 'Descuento',
  find = 'Buscar',
  delete = 'Eliminar',
  print = 'Imprimir',
  print_design = 'Imprimir_Diseño',
  authorizations = 'Autorizar',
  sendMail = 'Enviar',
  export = 'Exportar',
  import = 'Importar',
  export_design = 'Exportar_diseño',
  broom = 'Limpiar',
  times = 'Salir',
  disable = 'Anular',
  prices = 'Precios',
  credit = 'Credito',
  payments = 'Pagos',
  clone = 'Duplicar',
  advances = 'Anticipos',
  prev = 'Anterior',
  next = 'Siguiente',
  regenerate = 'Regenerar',
  facturar = 'Facturar',
  extract = 'Extraer',
  generar_xml = 'Generar_xml',
  methodsPay = 'Otros',
  approve = 'Aprobar',
  decline = 'Rechazar',
  generar = 'Generar',
  generarAsientos = 'Generar asientos',
  close = 'Salir',
  view = 'Ver',
  abonar = 'Abonar',
  saldos = 'Saldos',
  advancesEnter = 'Anticipos',
  cartera = 'Cartera',
  carteraVencida = 'Cartera_vencida',
  verAbonos = 'Ver_abonos',
  verAsiento = 'Ver_asiento',
  recalculate = 'Recalcular',
  refresh = 'Refrescar',
  cashReceipts = 'Ingresos_de_caja',
  check = 'Cheque',
  checks_pf = 'Cheques_pf',
  transfers = 'Transferencias',
  digitize = 'Digitalizar',
  providers = 'Proveedores',
  guides = 'Guias',
  woocommerce = 'Woocommerce',
  unify = 'Unificar',
  assembleKit = 'Armar_Kit',
  fullScreen = 'Pantalla_Completa',
  barCode = 'Codigo_Barras',
  fromExcel = 'Desde_Excel',
  audit = 'Auditoria',
  editPrice = 'Editar_Precio',
  deactivateItem = 'Desactivar_Item',
  download = 'Descarga',
  series = 'Series',
  seeRequestLots = 'Pedimentos_Lotes',
  lots = 'Lotes',
  resumen = 'Resumen',
  rebuildKardex = 'Reconstruir',
  movements = 'Movimientos',
  consolidate = 'Consolidar',
  shipments = 'Envios',
  reception = 'Recepcion',
  archived = 'Archivado',
  anular = 'Anular',
  permissions = 'Permisos',
  assignLocalUsers = 'Asignar_usuarios_a_locales',
  assign = 'Asignar',
  users = 'Usuarios',
  assignPositionOrganizationChart = 'Asignar_Roles_Organigrama',
  associate = 'Asociar',
  dialing = 'Marcacion',
  checkn = 'Check',
  salaries = 'Sueldos',
  archive = 'Archivo',
  rols = 'Roles',
  process = 'Procesar',
  all = 'Todos',
  attach = 'Adjuntar',
  generateBooks = 'Generar libros',
  homologate = 'Homologar',
  zynced = 'Sincronizados',
  deactivateVarious = 'Desactivar varios',
}

export type MenuButton = {
  id: string
  icon: IconDefinition | null
  text: string
  shortCut: MenuShortCut | null
}
