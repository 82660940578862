import React from 'react'
import { CAlert, CCol } from '@coreui/react-pro'
import { VentasService } from '../../../../../services/ventas.service'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Barcode from '../../../../../../../views/componentes/barcode/barcode'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import { InventarioService } from '../../../../../../../modulos/inventario/services/inventario.service'
import * as InventarioTypes from '../../../../../../inventario/store/types'
import ValidationSummary from 'devextreme-react/validation-summary'
import TextBox from 'devextreme-react/text-box'
import {
  Validator,
  RequiredRule,
  PatternRule,
  NumericRule,
  AsyncRule,
  StringLengthRule,
} from 'devextreme-react/validator'
import NumberBox from 'devextreme-react/number-box'
import Switch from 'devextreme-react/switch'
import { BuscarLineaLookUp } from '../../../../../../inventario/components/buscarLinea/buscarLinea'
import { BuscarGrupoLookUp } from '../../../../../../inventario/components/buscarGrupo/buscarGrupo'
import { BuscarMarcaLookUp } from '../../../../../../inventario/components/buscarMarca/buscarMarca'
import { BuscarIceLookUp } from '../../../../../../inventario/components/buscarIce/buscarIce'
import BuscarUnidadLookUp from '../../../../../../inventario/components/buscarUnidad/buscarUnidad'
import { addToast } from '../../../../../../../store/toasterReducer'
import { ToastTypes } from '../../../../../../../store/types'
import {
  lh,
  MessagesKeys,
} from '../../../../../../../helpers/localizationHelper'
import ValidationGroup from 'devextreme-react/validation-group'
import { isMobileOnly } from 'react-device-detect'
import { PopupContent } from '../../../../../../../views/componentes/popupContent'
import { utilidades } from '../../../../../../../helpers/utilidades'
import { LoadPanel } from 'devextreme-react/load-panel'
import { RootState } from '../../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../../store/reducers'
import { findOnObject } from '../../../../../../../helpers/Helper'
import { ECountry } from '../../../../../../../store/enum/countries'
import TipoItemLookUp from '../../../../../../componentes/tipoItemLookUp/tipoItemLookUp'
import { TTipoItem } from '../../../../../../componentes/tipoItemLookUp/store/types'
import { getTipoItems } from '../../../../../../componentes/tipoItemLookUp/helper/helper'
import { consoleService } from '../../../../../../../services/console.service'
import {
  EAplicationsAcatha,
  EModulosAcatha,
} from '../../../../../../../store/enumsAplication'
import { OptionTiposImpuestos } from '../../../../../../componentes/tiposImpuestosLookup/types/types'
import { tiposImpuestodServices } from '../../../../../../componentes/tiposImpuestosLookup/services/tiposImpuestos.service'
import SelectBox from 'devextreme-react/select-box'

const FormularioItem = (props: IFormularioItemProps) => {
  const {
    codigoBarras,
    tipo,
    funcion,
    resetFunction,
    currentTab,
    transaccion,
    modulo = EModulosAcatha.Ventas,
  } = props

  const validationGroupRef = React.useRef<any>()

  const empresaSession = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const menuSession = useSelector((state: RootState) => state.global.menu)
  const contratoEmpresaState = useSelector(
    (state: RootState) => state.pagosSuscripcion.contratoEmpresa,
  )

  const dispatch = useDispatch()
  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )

  const loader = useSelector((state: RootState) => state.global.loader)
  const [esNuevo, setEsnuevo] = React.useState(false)
  const [errorInfo, seterrorInfo] = React.useState<ErrorInfo>({
    tieneError: false,
  })
  const [itemModificar, setItemModificar] =
    React.useState<InventarioTypes.ItemInventario | null>(null)
  const [datos, setDatos] = React.useState<DatosModificarItem>(
    datosModificarItemDefaultValue,
  )
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [availableInventario, setAvailableInventario] =
    React.useState<boolean>(false)
  const [unidades, setUnidades] = React.useState<
    Array<InventarioTypes.UnidadInventario> | []
  >([])
  const [unidad, setUnidad] =
    React.useState<InventarioTypes.UnidadInventario | null>(null)
  const [linea, setLinea] = React.useState<number | null>(null)
  const [grupo, setGrupo] = React.useState<number | null>(null)
  const [marca, setMarca] = React.useState<number | null>(null)
  const [tipoItemExportar, setTipoItemExportar] =
    React.useState<TTipoItem | null>(null)
  const [isValidBarCode, setIsValidBarCode] = React.useState<boolean>(true)

  const verificarTexto = (cadena) => {
    // Expresión regular para buscar caracteres especiales
    const regex = /[^a-zA-Z0-9]/

    // Verificar si la cadena contiene caracteres especiales
    if (regex.test(cadena)) {
      console.error('La cadena contiene caracteres especiales.')
      return false
    }

    // La cadena solo contiene letras y números
    consoleService.log('La cadena contiene solo letras y números.')
    return true
  }

  function truncarDecimales(numero: number, decimales: number): number {
    const factor = Math.pow(10, decimales)
    return Math.floor(numero * factor) / factor
  }

  const onHandleSubmit = async (values: DatosModificarItem) => {
    console.log('onHandleSubmit', values)

    setIsSubmitting(true)
    playLoader()
    try {
      const validationResult =
        await validationGroupRef.current.instance.validate()
      consoleService.log('validationResult', validationResult)
      if (validationResult.isValid === false) {
        stopLoader()
        setShowErrorPopup(true)
        setShowErrorMessages(validationResult.brokenRules)
        return false
      }
      const fnGuardar = async () => {
        try {
          if (!verificarTexto(values.codigoBarras)) {
            dispatch(
              addToast({
                autoHide: true,
                content:
                  'El Cod. de barras no tiene el formato correcto debe contener letras y numeros.',
                fade: true,
                id: '',
                title: 'Revisar errores',
                type: ToastTypes.Danger,
              }),
            )
            stopLoader()
            return false
          }

          if (values?.impuesto === true) {
            if (datos?.tipoIva && datos?.tipoIva?.valor < 0) {
              dispatch(
                addToast({
                  autoHide: true,
                  content: 'Debe seleccionar el tipo de iva',
                  fade: true,
                  id: '',
                  title: 'Revisar errores',
                  type: ToastTypes.Danger,
                }),
              )
              stopLoader()
              return false
            }
          }

          let codigoImpuesto: number | null = null
          let tipoItem = 2

          let pvd_: number = values.pvd
          let itemConIva = false

          if (empresaState.codigoPais === ECountry.ElSalvador) {
            tipoItem = Number(tipoItemExportar?.codigo)

            console.log('tipoItem', tipoItem)
            console.log('values?.impuesto 1', values?.impuesto)

            codigoImpuesto = 3

            codigoImpuesto = values.tipoIva?.codigo

            if (codigoImpuesto === 3) {
              itemConIva = true
            }
          } else {
            if (transaccion === 'ventas') {
              tipoItem = values.servicio ? 2 : 1
            } else if (transaccion === 'compras') {
              tipoItem = 1
            }

            codigoImpuesto = values.impuesto === true ? 3 : 2
            if (values?.impuesto === true) {
              codigoImpuesto = values.tipoIva?.codigo
            }

            if (codigoImpuesto === 2) {
              itemConIva = true
            }
          }

          if (
            contratoEmpresaState.codigoPlanVigente &&
            [6, 5, 1].includes(contratoEmpresaState.codigoPlanVigente)
          ) {
            pvd_ = values.pvp
          }

          console.log('codigoImpuesto', codigoImpuesto)

          let pvp = values.pvp
          let pvd = pvd_

          if (itemConIva) {
            pvp = pvp / (1 + datos?.tipoIva?.valor / 100)
            pvd = pvd / (1 + datos?.tipoIva?.valor / 100)
          }

          //pvd = truncarDecimales(pvd, empresaSession.precision ?? 4)
          //pvp = truncarDecimales(pvp, empresaSession.precision ?? 4)

          const restRegistro = await VentasService.modificarItem({
            codigo: esNuevo ? 0 : itemModificar?.itemCodigo ?? 0,
            descripcion: values.descripcion,
            codigoBarras: values.codigoBarras,
            codigoUsu: values.codigoUsu,
            pvp: pvp,
            pvd: pvd,
            codigoImpuesto: codigoImpuesto,
            tipoCodigo: tipoItem,
            codigoUnidadVenta: values.unidad?.codigo ?? 34,
            codigoGrupo: values.grupo?.codigo ?? 0,
            codigoLinea: values.linea?.codigo ?? 1,
            codigoMarca: values.marca?.codigo ?? 0,
            iceTipoIce: values?.iceTipoIce ?? 0,
            iceCodigoGradoAlcoholico: values.iceCodigoGradoAlcoholico,
            iceGradoAlcoholico: values.iceGradoAlcoholico,
            iceCapacidaCm3: values.iceCapacidaCm3,
            iceCodigoImpuesto: values.iceCodigoImpuesto,
            iceCodigoClasificacion: values.iceCodigoClasificacion,
            iceCodigoMarca: values.iceCodigoMarca,
            iceEstado: values.iceEstado,
            iceCodigoPresentacion: values.iceCodigoPresentacion,
            iceCodigoUnidad: values.iceCodigoUnidad,
            iceGramosAzucar: values.iceGramosAzucar,
            ice: values.ice,
          })
          console.log('restRegistro : ', restRegistro)
          stopLoader()
          dispatch(
            addToast({
              autoHide: true,
              content:
                esNuevo === true
                  ? 'Registro Satisfactorio.'
                  : 'Item actualizado',
              fade: true,
              id: '',
              title: 'Guardar Item',
              type: ToastTypes.Success,
            }),
          )
          if (props.onChanged) {
            props.onChanged(values.codigoBarras)
          }
        } catch (error) {
          stopLoader()
          consoleService.error(error)
          dispatch(
            addToast({
              autoHide: true,
              content: error,
              fade: true,
              id: '',
              title: 'Guardar Item',
              type: ToastTypes.Danger,
            }),
          )
        }
      }

      if (validationResult.complete) {
        validationResult.complete.then(async (resolve) => {
          await resolve
          if (!resolve.isValid) {
            stopLoader()
            setIsSubmitting(false)
            return
          }
          fnGuardar()
        })
      } else {
        if (!validationResult.isValid) {
          setIsSubmitting(false)
          return
        } else {
          fnGuardar()
        }
      }
    } catch (error) {
      stopLoader()
      consoleService.error(error)
      dispatch(
        addToast({
          autoHide: true,
          content: error,
          fade: true,
          id: '',
          title: 'Guardar Item',
          type: ToastTypes.Danger,
        }),
      )
    }
  }

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const resetItem = React.useCallback(async () => {
    validationGroupRef.current.instance.reset()

    playLoader()
    const resUnidades = await InventarioService.getUnidades()
    if (resUnidades.length > 0) {
      setUnidades(resUnidades)
      const findUnidades = resUnidades.find((x) => x.codigo === 34)
      if (findUnidades) {
        setUnidad(findUnidades)
      }
    }

    const data = await tiposImpuestodServices.getTipos()

    let optionsLookup = []
    if (data?.auto && data?.auto.length > 0) {
      optionsLookup = data.auto.map((option) => {
        return {
          codigo: Number(option?.codigo) ?? 0,
          descripcion: option?.descripcion ?? '',
          valor: Number(option?.valor) ?? 0,
        }
      })
    }

    let servicio: boolean = false
    if (modulo === EModulosAcatha.Ventas) {
      servicio = true
    }

    let tipoItem = null

    let tipoIva = optionsLookup.length > 0 ? optionsLookup[4] : null
    if (empresaState?.codigoPais === ECountry.ElSalvador) {
      tipoIva = optionsLookup.length > 0 ? optionsLookup[3] : null

      const tipoItemsApi = await getTipoItems()
      tipoItem = tipoItemsApi[3] // por defecto tipo item => Ambos Bienes y Servicios
      setTipoItemExportar(tipoItem)
    } else if (empresaState?.codigoPais === ECountry.Ecuador) {
      tipoIva = optionsLookup.length > 0 ? optionsLookup[0] : null
      tipoItem = null
    }

    const dataInit = {
      codigoBarras: '',
      descripcion: '',
      pvp: 0,
      pvd: 0,
      impuesto: true,
      ice: false,
      servicio: servicio,
      iceCodigoGradoAlcoholico: '',
      iceGradoAlcoholico: 0,
      iceCapacidaCm3: 0,
      iceCodigoImpuesto: '',
      iceCodigoClasificacion: '',
      iceCodigoMarca: '',
      iceEstado: '',
      iceCodigoPresentacion: '',
      iceCodigoUnidad: '',
      iceGramosAzucar: 0,
      unidad: null,
      tiposIva: optionsLookup.length > 0 ? optionsLookup : [],
      tipoIva: tipoIva,
      tipoItem: tipoItem,
    }

    setDatos(dataInit)

    setEsnuevo(true)
    stopLoader()
  }, [playLoader, stopLoader, modulo, empresaState])

  const cargarItem = React.useCallback(
    async (codigoBarras: string) => {
      playLoader()
      try {
        // setLoading(true);

        validationGroupRef.current.instance.reset()
        const resUnidades = await InventarioService.getUnidades()

        if (codigoBarras.length > 0) {
          const producto = await InventarioService.getItemXCodigoBarras(
            codigoBarras,
          )
          const data = await tiposImpuestodServices.getTipos()
          let optionsLookup = []
          if (data?.auto && data?.auto.length > 0) {
            optionsLookup = data.auto.map((option) => {
              return {
                codigo: Number(option?.codigo) ?? 0,
                descripcion: option?.descripcion ?? '',
                valor: Number(option?.valor) ?? 0,
              }
            })
          }

          if (producto.itemCodigo !== undefined) {
            let pvp = producto.itemPVP
            let pvd = producto.itemPVD
            if (producto.itemImpuestoCodigo === 3) {
              pvp = producto.itemPVPIVA
              pvd = producto.itemPVDIVA
            }

            setItemModificar(producto)

            consoleService.log('producto', producto)

            const datosItem = {
              codigoBarras: producto.itemBarras,
              codigoUsu: producto?.itemCodigoUsuario ?? '',
              descripcion: producto.itemDescripcion,
              pvd: pvd,
              pvp: pvp,
              iceCapacidaCm3: parseFloat(producto.iceCapacidaCm3),
              iceCodigoClasificacion: producto.iceCodigoClasificacion,
              iceCodigoGradoAlcoholico: producto.iceCodigoGradoAlcoholico,
              iceCodigoImpuesto: producto.iceCodigoImpuesto,
              iceCodigoMarca: producto.iceCodigoMarca,
              iceCodigoPresentacion: producto.iceCodigoPresentacion,
              iceCodigoUnidad: producto.iceCodigoUnidad,
              iceEstado: producto.iceEstado,
              iceGradoAlcoholico: parseFloat(producto.iceGradoAlcoholico),
              iceGramosAzucar: parseFloat(producto.iceGramosAzucar),
              iceTipoIce: producto.itemIce, // producto.ice 0, //producto.iceTipoIce,
              ice: producto.itemIce > 0 ? true : false, // producto.ice,
              grupo: {
                codigo: producto.itemGrupoCodigo,
                descripcion: '',
                estadoCodigo: 0,
                estadoNombre: '',
                items: 0,
                perteneceCodigo: 0,
                perteneceNombre: '',
                categoriaWeb: 0,
              },
              // impuesto: { codigo: producto.itemImpuestoCodigo, descripcion: '', valor: 0 },
              impuesto:
                producto.itemImpuestoCodigo === 3 ||
                producto.itemImpuestoCodigo === 5
                  ? true
                  : false,
              linea: { codigo: producto.itemLineaCodigo, descripcion: '' },
              marca: { codigo: producto.itemMarcaCodigo, nombre: '' },
              unidad: null,
              servicio: producto.itemTipo === 2 ? true : false,
            }
            if (producto.itemUnidadVentaCodigo && resUnidades.length > 0) {
              const findUnidades = await resUnidades.find(
                (x) => x.codigo === producto.itemUnidadVentaCodigo,
              )
              if (findUnidades) {
                datosItem.unidad = findUnidades
                setUnidad(findUnidades)
                setUnidades(resUnidades)
              }
            }
            if (producto.itemLineaCodigo) {
              setLinea(producto.itemLineaCodigo)
            }
            if (producto.itemGrupoCodigo) {
              setGrupo(producto.itemGrupoCodigo)
            }
            if (producto.itemMarcaCodigo) {
              setMarca(producto.itemMarcaCodigo)
            }
            if (producto.itemIce > 0) {
              datosItem.ice = producto.itemIce > 0 ? true : false
            }

            datosItem.tiposIva = optionsLookup
            if (datosItem.impuesto) {
              const findImpuestos = await optionsLookup.find(
                (x) => x.codigo === producto.itemImpuestoCodigo,
              )
              if (findImpuestos) {
                datosItem.tipoIva = findImpuestos
              }
            }

            if (empresaState.codigoPais === ECountry.ElSalvador) {
              const tipoItemsApi = await getTipoItems()
              const findItemTipo = await tipoItemsApi.find(
                (x) => x.codigo === producto.itemTipo,
              )
              if (findItemTipo) {
                setTipoItemExportar(findItemTipo ?? null)
              }
            }

            if (
              /[a-zA-Z]/.test(datosItem.codigoBarras) &&
              /[^a-zA-Z0-9]/.test(datosItem.codigoBarras)
            ) {
              setIsValidBarCode(false)
              consoleService.log(
                'El codigoBarras contiene la cadena con caracter especial ',
              )
            } else {
              setIsValidBarCode(true)
            }
            setDatos(datosItem)
            stopLoader()
          } else {
            stopLoader()
            // eslint-disable-next-line no-throw-literal
            throw (
              'Error al cargar el producto, ningún item fue devuelto con el código de barras ' +
              codigoBarras
            )
          }
        } else {
          const findUnidades = await resUnidades.find((x) => x.codigo === 34)

          setDatos({ ...datos, unidad: findUnidades ?? null, servicio: true })

          stopLoader()
          setEsnuevo(true)
        }
      } catch (error) {
        stopLoader()
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'Error al cargar ITEM. Error:\n' + error,
            fade: true,
            title: 'Item',
            type: ToastTypes.Danger,
          }),
        )
        // if (props.onCancel) {
        //   props.onCancel();
        // }
      }
    },
    [dispatch, datos, stopLoader, playLoader, empresaState],
  )

  const validateAsyncSeleccionUnidad = React.useCallback((datoSeleccion) => {
    if (datoSeleccion['value']) {
      return Promise.resolve()
    } else {
      return Promise.reject('Unidad : Debe seleccionar una opción.')
    }
  }, [])

  const validateAsyncSeleccionTipoIva = React.useCallback((datoSeleccion) => {
    if (datoSeleccion['value'] && datoSeleccion['value']['codigo'] > 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(' Tipo IVA : Debe seleccionar una opción.')
    }
  }, [])

  const verificarModulosActivos = React.useCallback(() => {
    const find = findOnObject(menuSession, 'id', '1')
    if (find.length > 0) {
      setAvailableInventario(true)
    } else {
      setAvailableInventario(false)
    }
  }, [menuSession])

  const onChangeUnidad = React.useCallback(
    async (data) => {
      if (data !== null) {
        setUnidad(data)
        setDatos({ ...datos, unidad: data })
      }
    },
    [datos],
  )

  const onChangeLinea = React.useCallback(
    async (data) => {
      if (data !== null) {
        setLinea(data)
        setDatos({ ...datos, linea: data })
      }
    },
    [datos],
  )

  const onChangeTipoIce = React.useCallback(
    async (data) => {
      if (data !== null) {
        setDatos({ ...datos, iceTipoIce: data })
      }
    },
    [datos],
  )

  const onChangeGrupo = React.useCallback(
    async (data) => {
      if (data !== null) {
        setGrupo(data)
        setDatos({ ...datos, grupo: data })
      }
    },
    [datos],
  )

  const onChangeMarca = React.useCallback(
    async (data) => {
      if (data !== null) {
        setMarca(data)
        setDatos({ ...datos, marca: data })
      }
    },
    [datos],
  )

  const onChangeTipoItemExportar = React.useCallback((v) => {
    setTipoItemExportar(v)
  }, [])

  const onChangeTipoIva = React.useCallback(
    (v) => {
      setDatos({ ...datos, tipoIva: v })
    },
    [datos],
  )

  const onChangeServicio = React.useCallback(
    (e) => {
      setDatos({ ...datos, servicio: e })
      if (e === true && esNuevo === true) {
        const findUnidades = unidades.find((x: any) => x.codigo === 34)
        setDatos({ ...datos, unidad: findUnidades ?? null, servicio: e })
      }
    },
    [datos, unidades, esNuevo],
  )

  const getItemDisplayExpr = React.useCallback((item: any) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  React.useEffect(() => {
    verificarModulosActivos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (funcion === 'Guardar') {
      resetFunction()
      onHandleSubmit(datos)
    } else if (funcion === 'Deshacer') {
      if (currentTab === 'Editar') {
        resetFunction()
        cargarItem(codigoBarras)
      } else if (currentTab === 'Nuevo') {
        resetFunction()
        resetItem()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  React.useEffect(() => {
    if (currentTab === 'Nuevo') {
      resetItem()
    } else if (currentTab === 'Editar') {
      cargarItem(codigoBarras)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const loaderGlobal = () => {
    return (
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={loader.show}
        showIndicator={true}
        shading={true}
        showPane={true}
        message="Buscando..."
      />
    )
  }

  return (
    <>
      {loaderGlobal()}
      <ValidationGroup id={`valGroupModificarItem`} ref={validationGroupRef}>
        {!isMobileOnly && (
          <RowContainer>
            <CustomCol>
              <ValidationSummary />
            </CustomCol>
          </RowContainer>
        )}
        {errorInfo.tieneError && (
          <RowContainer>
            <CustomCol xs="10">
              <CAlert
                key="modificaItemErrorAlert"
                color="danger"
                // visible={ true}
                // closeButton
                onChange={() => {
                  seterrorInfo({ tieneError: false })
                }}
              >
                {errorInfo.mensaje}
              </CAlert>
            </CustomCol>
          </RowContainer>
        )}
        <RowContainer>
          <CustomCol md="6">
            <Labeled label={'Barras '}>
              <TextBox
                name="codigoBarras"
                placeholder={'Barras'}
                value={datos?.codigoBarras}
                onValueChange={(e) => {
                  if (e) {
                    const tmpText = e
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                    setDatos({
                      ...datos,
                      codigoBarras: tmpText,
                      codigoUsu: tmpText,
                    })
                  }
                }}
                onKeyDown={(e) => {
                  utilidades.filtrarTeclasRangos(e, [
                    { min: 18, max: 18 },
                    { min: 58, max: 64 },
                    { min: 106, max: 221 },
                  ])
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              >
                <Validator>
                  <RequiredRule
                    trim
                    message={lh.getMessage(
                      MessagesKeys.GlobalCampoRequerido,
                      'Código de barras',
                    )}
                  />
                  <PatternRule
                    message={'El Cod. de barra debe contener letras y numeros.'}
                    pattern={codigoBarrasRegex}
                  />
                  <StringLengthRule
                    max={25}
                    message={'No puede exceder los 25 caracteres'}
                  />
                  <StringLengthRule
                    min={2}
                    message={'Debe tener al menos 2 caracteres'}
                  />
                  {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol md="6">
            {isValidBarCode && <Barcode text={datos.codigoBarras} />}
            {!isValidBarCode && (datos?.codigoBarras ?? '')}
          </CustomCol>
        </RowContainer>

        <RowContainer>
          <CustomCol md="6">
            <Labeled label={'Código '}>
              <TextBox
                name="codigoCodigo"
                placeholder={'Código'}
                value={datos?.codigoUsu}
                onValueChange={(e) => {
                  setDatos({ ...datos, codigoUsu: e })
                }}
                onKeyDown={(e) => {
                  utilidades.filtrarTeclasRangos(e, [
                    { min: 18, max: 18 },
                    { min: 58, max: 64 },
                    { min: 106, max: 221 },
                  ])
                }}
                inputAttr={{
                  autocomplete: 'nope',
                }}
              >
                <Validator>
                  <PatternRule
                    message={'El Código. debe contener letras y numeros.'}
                    pattern={codigoBarrasRegex}
                  />
                  <StringLengthRule
                    max={25}
                    message={'No puede exceder los 25 caracteres'}
                  />
                  <StringLengthRule
                    min={2}
                    message={'Debe tener al menos 2 caracteres'}
                    ignoreEmptyValue={true}
                  />
                  {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>

        <RowContainer>
          <CustomCol xs="12">
            <Labeled label="Descripción">
              <TextBox
                name="descripcion"
                placeholder="Descripción"
                value={datos.descripcion ?? ''}
                onValueChange={(e) => {
                  setDatos({ ...datos, descripcion: e })
                }}
              >
                <Validator>
                  <RequiredRule
                    trim
                    message={lh.getMessage(
                      MessagesKeys.GlobalCampoRequerido,
                      'Descripción',
                    )}
                  />
                  <StringLengthRule
                    min={3}
                    message={'Debe tener al menos 3 caracteres'}
                  />
                  {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs="6" md="2">
            <Labeled label="PVP Inc IVA">
              <NumberBox
                value={datos.pvp}
                onValueChange={(e) => {
                  setDatos({ ...datos, pvp: e })
                }}
              >
                <Validator>
                  <RequiredRule
                    trim
                    message={lh.getMessage(
                      MessagesKeys.GlobalCampoRequerido,
                      'PVP',
                    )}
                  />
                  <NumericRule />
                </Validator>
              </NumberBox>
            </Labeled>
          </CustomCol>

          {/* 6,5,1 son planes de facturacion */}
          {empresaSession &&
            [6, 5, 1].includes(contratoEmpresaState.codigoPlanVigente) ===
              false && (
              <>
                <CustomCol xs="6" md="2">
                  <Labeled label={'PVD Inc IVA'}>
                    <NumberBox
                      name="pvd"
                      value={datos.pvd}
                      onValueChange={(e) => {
                        if (e) {
                          setDatos({ ...datos, pvd: e })
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule
                          trim
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'PVD',
                          )}
                        />
                        <NumericRule />
                      </Validator>
                    </NumberBox>
                  </Labeled>
                </CustomCol>
              </>
            )}
          {/* <CustomCol xs='6' md='3'>
            <Labeled label='IVA'>
              <SelectBox
                id='selectModificarItemImpuesto'
                options={tiposIva}
                placeholder='Impuesto'
                onChange={(value) => {
                  setDatos({ ...datos, impuesto: value })
                }}
                selected={datos.impuesto}
                displayExpr='descripcion'
                keyExpr='codigo'
              >
                <Validator>
                  <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Impuesto')} />
                </Validator>
              </SelectBox>
            </Labeled>
          </CustomCol> */}
          <CustomCol xs="6" sm="1">
            <Labeled label="I.V.A.">
              <Switch
                switchedOnText="SI"
                switchedOffText="NO"
                value={datos.impuesto}
                onValueChange={(e) => {
                  setDatos({ ...datos, impuesto: e })
                }}
              />
            </Labeled>
          </CustomCol>

          {datos.impuesto && (
            <CustomCol xs="6" sm="3">
              <Labeled label="Tipo IVA">
                <SelectBox
                  id="tipoIVASelect"
                  dataSource={datos?.tiposIva ?? []}
                  searchEnabled
                  displayExpr={getItemDisplayExpr}
                  searchExpr={['descripcion']}
                  searchTimeout={500}
                  value={datos?.tipoIva}
                  onValueChange={onChangeTipoIva}
                >
                  <Validator>
                    <AsyncRule
                      reevaluate
                      message={'- Tipo IVA: Debe seleccionar una opción'}
                      validationCallback={validateAsyncSeleccionTipoIva}
                    />
                  </Validator>
                </SelectBox>
              </Labeled>
            </CustomCol>
          )}

          <CustomCol xs="6" sm="1">
            <Labeled label="I.C.E.">
              <Switch
                switchedOnText="SI"
                switchedOffText="NO"
                value={datos.ice}
                onValueChange={(e) => {
                  setDatos({ ...datos, ice: e })
                }}
              />
            </Labeled>
          </CustomCol>

          {empresaState?.codigoPais === ECountry.ElSalvador && (
            <CustomCol xs="6" sm="6">
              <Labeled label="Tipo Item">
                <TipoItemLookUp
                  selected={tipoItemExportar ?? null}
                  onChanged={onChangeTipoItemExportar}
                />
              </Labeled>
            </CustomCol>
          )}

          {empresaSession &&
            contratoEmpresaState.codigoPlanVigente &&
            availableInventario === true && (
              <>
                <CustomCol xs="1" sm="1">
                  <Labeled label="Servicio">
                    <Switch
                      switchedOnText="SI"
                      switchedOffText="NO"
                      value={datos.servicio}
                      onValueChange={onChangeServicio}
                    />
                  </Labeled>
                </CustomCol>
              </>
            )}
        </RowContainer>
        <RowContainer>
          {empresaSession &&
            contratoEmpresaState.codigoPlanVigente &&
            availableInventario === true && (
              <>
                <CCol md="6">
                  <Labeled label="Unidad">
                    <BuscarUnidadLookUp
                      selected={unidad ?? null}
                      onChanged={(e) => {
                        if (e !== null || e !== undefined) {
                          onChangeUnidad(e)
                        }
                      }}
                      provider={unidades ?? []}
                    >
                      <Validator>
                        <AsyncRule
                          reevaluate
                          message={'- Unidad: Debe seleccionar una opción'}
                          validationCallback={validateAsyncSeleccionUnidad}
                        />
                      </Validator>
                    </BuscarUnidadLookUp>
                  </Labeled>
                </CCol>

                <CCol md="6">
                  <Labeled label="Linea">
                    <BuscarLineaLookUp
                      selected={linea ?? null}
                      onChanged={(e) => {
                        if (e !== null) {
                          onChangeLinea(e)
                        }
                      }}
                    />
                  </Labeled>
                </CCol>

                <CCol md="6">
                  <Labeled label="Grupo">
                    <BuscarGrupoLookUp
                      selected={grupo ?? null}
                      onChanged={(e) => {
                        if (e !== null) {
                          onChangeGrupo(e)
                        }
                      }}
                    />
                  </Labeled>
                </CCol>
                <CCol md="6">
                  <Labeled label="Marca">
                    <BuscarMarcaLookUp
                      selected={marca ?? null}
                      onChanged={(e) => {
                        if (e !== null) {
                          onChangeMarca(e)
                        }
                      }}
                    />
                  </Labeled>
                </CCol>
              </>
            )}
        </RowContainer>
        {datos.ice && (
          <RowContainer>
            <CCol xs="12">
              <Labeled label="Codigos ICE">
                <RowContainer>
                  <CCol xs="6" md="3">
                    <Labeled label="Tipo ICE">
                      <BuscarIceLookUp
                        selected={datos?.iceTipoIce}
                        onChanged={(ice) => {
                          if (ice !== null) {
                            onChangeTipoIce(ice)
                          }
                        }}
                      />
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Cod. Grado Alcoholico">
                      <TextBox
                        name="iceCodigoGradoAlcoholico"
                        value={datos.iceCodigoGradoAlcoholico}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceCodigoGradoAlcoholico: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Cód. Grado Alcoholico',
                            )}
                          />
                          <NumericRule />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Grado Alcoholico">
                      <NumberBox
                        name="iceCodigoGradoAlcoholico"
                        value={datos.iceGradoAlcoholico}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceGradoAlcoholico: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Grado Alcoholico',
                            )}
                          />
                          <NumericRule />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Capacidad cm3">
                      <NumberBox
                        name="iceCapacidaCm3"
                        value={datos.iceCapacidaCm3}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceCapacidaCm3: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Capacidad cm3',
                            )}
                          />
                          <NumericRule />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Código Impuesto">
                      <TextBox
                        name="iceCodigoImpuesto"
                        placeholder="Código Impuesto"
                        value={datos?.iceCodigoImpuesto}
                        onValueChanged={(e) => {
                          if (e?.event !== undefined) {
                            setDatos({ ...datos, iceCodigoImpuesto: e?.value })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Código Impuesto',
                            )}
                          />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Código Clasificación">
                      <TextBox
                        name="iceCodigoClasificacion"
                        placeholder="Código Impuesto"
                        value={datos?.iceCodigoClasificacion}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceCodigoClasificacion: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Código Clasificación',
                            )}
                          />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Estado">
                      <TextBox
                        name="iceCodigoClasificacion"
                        placeholder="Estado"
                        value={datos?.iceEstado}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceEstado: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Estado ICE',
                            )}
                          />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Código Presentación">
                      <TextBox
                        name="iceCodigoPresentacion"
                        placeholder="Código Presentación"
                        value={datos?.iceCodigoPresentacion}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceCodigoPresentacion: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Código Presentación',
                            )}
                          />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Código Unidad">
                      <TextBox
                        name="iceCodigoUnidad"
                        placeholder="Código Unidad"
                        value={datos?.iceCodigoUnidad}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceCodigoUnidad: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Código Unidad',
                            )}
                          />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Gramos Azucar">
                      <NumberBox
                        name="iceGramosAzucar"
                        value={datos.iceGramosAzucar}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceGramosAzucar: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Gramos Azucar',
                            )}
                          />
                          <NumericRule />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CCol>
                  <CCol xs="6" md="3">
                    <Labeled label="Ice. Cod. Marca">
                      <TextBox
                        name="iceCodMarca"
                        value={datos.iceCodigoMarca}
                        onValueChange={(e) => {
                          if (e !== undefined) {
                            setDatos({ ...datos, iceCodigoMarca: e })
                          }
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            trim
                            message={lh.getMessage(
                              MessagesKeys.GlobalCampoRequerido,
                              'Ice. Cod. Marca',
                            )}
                          />
                          <NumericRule />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                </RowContainer>
              </Labeled>
            </CCol>
          </RowContainer>
        )}

        {isMobileOnly && (
          <>
            <RowContainer className="ms-2">
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
            {showErrorPopup && (
              <PopupContent
                show={showErrorPopup}
                title={'Acatha'}
                subTitle={'Por favor revise las siguientes advertencias:'}
                onClose={() => {
                  setShowErrorPopup(false)
                  setShowErrorMessages([])
                }}
                size={'sm'}
                canClose={true}
              >
                <CAlert color="danger">
                  <ul className="errorList">
                    {showErrorMessages.map(function (item, id) {
                      return <li key={id}>{item['message']}</li>
                    })}
                  </ul>
                </CAlert>
              </PopupContent>
            )}
          </>
        )}
      </ValidationGroup>
    </>
  )
}

interface IFormularioItemProps extends React.PropsWithChildren {
  codigoBarras: string
  tipo: string
  // eslint-disable-next-line no-unused-vars
  onChanged?: (codigoBarras: string) => void
  onCancel?: () => void
  funcion: string
  resetFunction: () => void
  currentTab: string
  transaccion: string
  modulo?: EModulosAcatha | EAplicationsAcatha
}

type ErrorInfo = {
  tieneError: boolean
  mensaje?: string
}

type DatosModificarItem = {
  codigoBarras: string
  codigoUsu?: string
  descripcion: string
  pvp: number
  pvd: number
  impuesto?: boolean // TipoIva
  servicio?: boolean
  ice: boolean
  unidad?: InventarioTypes.UnidadInventario
  linea?: InventarioTypes.LineaInventario
  grupo?: InventarioTypes.GrupoInventario
  marca?: InventarioTypes.MarcaInventario
  iceTipoIce?: number
  iceCodigoGradoAlcoholico: string
  iceGradoAlcoholico: number
  iceCapacidaCm3: number
  iceCodigoImpuesto: string
  iceCodigoClasificacion: string
  iceCodigoMarca: string
  iceEstado: string
  iceCodigoPresentacion: string
  iceCodigoUnidad: string
  iceGramosAzucar: number
  tipoIva: OptionTiposImpuestos | null
  tiposIva: OptionTiposImpuestos[] | []
  tipoItem?: TTipoItem | null
}

const datosModificarItemDefaultValue: DatosModificarItem = {
  codigoBarras: '',
  descripcion: '',
  pvp: 0,
  pvd: 0,
  impuesto: true,
  servicio: true,
  ice: false,
  // iceTipoIce: 0,
  iceCodigoGradoAlcoholico: '',
  iceGradoAlcoholico: 0,
  iceCapacidaCm3: 0,
  iceCodigoImpuesto: '',
  iceCodigoClasificacion: '',
  iceCodigoMarca: '',
  iceEstado: '',
  iceCodigoPresentacion: '',
  iceCodigoUnidad: '',
  iceGramosAzucar: 0,
  tipoIva: null,
  tiposIva: [],
  tipoItem: null,
}

const codigoBarrasRegex: RegExp = new RegExp('^[a-zA-Z0-9]{2,25}$')

export default React.memo(FormularioItem)
