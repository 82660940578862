import React from 'react'
import { CBadge } from '@coreui/react-pro'
import { isMobile } from 'react-device-detect'
import {
  AutorizacionDocumentoVenta,
  GuiaInfo,
  VentaListado,
} from '../../../types/types'
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils'
import { VentasService } from '../../../services/ventas.service'
import {
  ResponseDataSv,
  TControlReporte,
  TPremiseReport,
  TS_AnulacionSV,
  TS_Auth,
  TS_ConstingencySV,
  TS_ConsumidorFinal,
  TS_CreditNote,
  TS_DebitNote,
  TS_DetalleDTE,
  TS_Extension,
  TS_InvoiceConsumidorFinal,
  TS_InvoicePagos,
  TS_MotivoContingencia,
  TS_Transmitter,
} from '../../../types/facturacion/elSalvador/types'
import { v4 as uuidv4 } from 'uuid'
import { utilidades } from '../../../../../helpers/utilidades'
import { consoleService } from '../../../../../services/console.service'
import {
  EModeloFacturacionSLV,
  EMonedaStandar,
  EOperationContitionSLV,
  ETipoTransmisionSLV,
  TAbrevDTESLV,
  TiposComprobantesSLV,
  tiposComprobantesToAbrev,
} from '../../../../../store/types'
import config from '../../../../../config/config'
import { messagesSvMiddleware } from '../../../../../localization/sv/messages-sv'
import {
  getAmbiente,
  JsonDteElectronicHelper,
} from '../../../../../helpers/sv/utilitiesSv'
import { tipoEstablecimientoService } from '../../../../componentes/lookUpTipoEstablecimiento/service/tipoEstablecimiento.service'
import { TipoEstablecimientoOption } from '../../../../componentes/lookUpTipoEstablecimiento/store/type'
import { EFormasPago } from '../../../../../store/enum/formasPago'
import { FormatProductsElectronicInvoiceSVCCF } from '../../../../../helpers/FormatProductsElectronicInvoiceSV'
import { TAutorizacionHaciendaSV } from '../types/types'
import { ETipoItemImpuesto } from '../../../../../services/itemEnum/enumSV'

export const VentasGridHelper = {
  getGridResultadoBusquedaHeigth,
  formatCellsIfDocummentIsDisabled,
  isSendMailIconVisible,
  isSendSriIconVisible,
  isRemoveIconVisible,
  customizeSummaryText,
  estadoElectronicoCell,
  convertirFechaTransaccionVenta,
  convertirFechaTransaccionGuiaRemision,
  anularDocumentoVenta,
  calculateCustomSummary,
  adapterAnulacionVenta,
  convertirFechaTransaccionGuiaRemisionSV,
  getCurrentFormattedDate,
  getCurrentFormattedTime,
  adapterConsumidorFinal,
  adapterCreditoFiscal,
  parseReportDataSVSujExcluido,
  adapterExportar,
  adapterNotaCredito,
  adapterNotaDebito,
  adapterSujetoExcluido,
  getErrorsDteSV,
  getTipoDocumento,
  parseReportDataSVExpo,
  adapterRetencion,
  adapterContingencia,
  parseReportDataFCFSV,
  parseReportDataSVCCF,
  parserReportDataSVNotaCredito,
  isOperationCondition,
  onDetectConditionPay,
  getObservacionesDteSV,
  captureValuesDteNoAuth,
  eliminarCamposCuerpoDocumento,
}

function getGridResultadoBusquedaHeigth() {
  if (isMobile) {
    if (window.innerWidth < 400) {
      return window.innerHeight - 320
    } else {
      return window.innerHeight - 260
    }
  } else {
    return undefined
  }
}

function convertirFecha(fecha: string): string {
  const partes = fecha.split('/')
  const fechaConvertida = `${partes[2]}-${partes[1]}-${partes[0]}`
  return fechaConvertida
}

const obtenerNombreTipoComprobante = (tipo: string) => {
  switch (tipo + '') {
    case '36':
      return 'NIT'

    case '13':
      return 'DUI'

    case '37':
      return 'Otro'

    case '03':
      return 'Pasaporte'

    case '02':
      return 'Carnet de Residente'

    default:
      return 'Otro'
  }
}

function getTipoDocumento(type: string): TiposComprobantesSLV {
  const tipo = type as TiposComprobantesSLV
  return tipo
}

function adapterAnulacionVenta(data: any): TS_AnulacionSV {
  const {
    info,
    empresa,
    formattedDate,
    formattedTime,
    codTipoEstablecimiento,
    desTipoEstablecimiento,
    codTipoId,
    numDocumento,
    numeroControlDte,
    ambiente = '00',
  } = data

  let nombreSolicita = info?.clienteNombre ?? ''
  if (info.tipoDocumentoCodigo === '14') {
    nombreSolicita = info?.proveedorNombre ?? ''
  }

  const esClientePorDefecto =
    numDocumento === '9999999999999' || numDocumento === '99999999999999'

  const ventaClientePorDefecto =
    esClientePorDefecto && info.tipoDocumentoCodigo == '01'
  const documentoAnular = {
    tipoDte: info.tipoDocumentoCodigo,
    codigoGeneracion: info.autorizacionHacienda.codigoGeneracion,
    selloRecibido: info.autorizacionHacienda.selloRecibido,
    numeroControl: numeroControlDte,
    fecEmi: convertirFecha(info.fecha),
    montoIva: info.iva,
    tipoDocumento:
      info.tipoDocumentoCodigo == '01' && esClientePorDefecto
        ? null
        : codTipoId,
    numDocumento:
      info.tipoDocumentoCodigo == '01' && esClientePorDefecto
        ? null
        : numDocumento, //viene nit y tipo de identificacion es dui
    nombre:
      info.tipoDocumentoCodigo == '01' && esClientePorDefecto
        ? null
        : nombreSolicita,
    telefono: getContacto(info.clienteTelefono),
    correo: getEmail(info?.clienteEmail),
    codigoGeneracionR: null,
  }
  let tipoAnulacion: number = 2
  if (info?.relacionado != null) {
    tipoAnulacion = 1
    documentoAnular.codigoGeneracionR = info?.relacionado ?? null
  }
  const objectAnular: TS_AnulacionSV = {
    idEnvio: 1,

    anulacion: {
      nit: empresa?.ruc,
      activo: true,
      dteJson: {
        identificacion: {
          version: 2, //no viene
          ambiente: ambiente,
          codigoGeneracion: uuidv4().toUpperCase(),
          fecAnula: getCurrentFormattedDate(),
          horAnula: getCurrentFormattedTime(),
        },
        emisor: {
          nit: empresa?.ruc,
          nombre: empresa?.nombre,
          tipoEstablecimiento: codTipoEstablecimiento,
          nomEstablecimiento: desTipoEstablecimiento,
          telefono: getContacto(empresa?.telefono),
          correo: getEmail(empresa?.email),
          ...getEstabPuntoMH(numeroControlDte),
        },
        documento: documentoAnular,
        motivo: {
          tipoAnulacion: tipoAnulacion,
          motivoAnulacion: 'Sin comentarios', //no viene
          nombreResponsable: empresa?.nombre,
          tipDocResponsable: '36',
          numDocResponsable: empresa?.ruc,
          nombreSolicita: !ventaClientePorDefecto
            ? nombreSolicita
            : empresa?.nombre,
          tipDocSolicita: '36',
          numDocSolicita: empresa?.ruc,
        },
      },
    },
  }

  return objectAnular
}

function obtenerDescripcionMsg(objeto: any): string[] {
  const mensajes: string[] = []

  if (typeof objeto !== 'object' || objeto === null) {
    return mensajes
  }

  for (const clave in objeto) {
    if (
      clave === 'mensajeMsg' ||
      clave === 'descripcionMsg' ||
      clave === 'mensaje' ||
      clave === 'observaciones' ||
      clave === 'error' ||
      clave === 'message'
    ) {
      if (typeof objeto[clave] === 'string') {
        mensajes.push(objeto[clave])
      } else if (typeof objeto[clave] === 'object') {
        mensajes.push(...obtenerDescripcionMsg(objeto[clave]))
      }
    } else if (typeof objeto[clave] === 'object') {
      mensajes.push(...obtenerDescripcionMsg(objeto[clave]))
    }
  }

  return mensajes
}

const reemplazos: { [key: string]: string } = {
  'identificacion.fecEmi': 'Fecha de Emisión',
}

const normalizarTexto = (
  texto: string,
  diccionario: { [key: string]: string },
): string => {
  let textoNormalizado = texto

  Object.keys(diccionario).forEach((clave) => {
    const regex = new RegExp(clave, 'g')
    textoNormalizado = textoNormalizado.replace(regex, diccionario[clave])
  })

  return textoNormalizado
}

function getObservacionesDteSV(data: ResponseDataSv) {
  const smsTitle: string = data?.descripcionMsg ?? ''
  let errors: string[] = []
  if (data.observaciones && data.observaciones.length > 0) {
    data.observaciones.forEach((clave) => {
      errors.push(normalizarTexto(clave, reemplazos))
    })
  } else {
    errors = []
  }
  return {
    smsTitle: smsTitle,
    errors: errors,
  }
}

function getErrorsDteSV(resultado: any) {
  let smsTitle: string = ''
  let errors: string[] = []

  //validar que no sea errorConexion
  if (resultado == 'errorConexion') {
    errors.push('Error de conexión')
  }

  if (
    resultado?.status === 500 ||
    resultado?.status === 400 ||
    resultado?.statusCode === 500 ||
    resultado?.statusCode === 400
  ) {
    const descripcion = obtenerDescripcionMsg(resultado)
    if (Array.isArray(descripcion)) {
      descripcion.map(function (key) {
        errors.push(key)
      })
    } else if (!Array.isArray(descripcion)) {
      const sms: any = descripcion ?? ''
      errors.push(String(sms))
    }
  } else {
    if (resultado?.error) {
      errors.push(resultado.message ?? 'Server sin conexión')
    }
  }

  if (resultado?.error === true) {
    smsTitle = resultado?.message
    if (typeof resultado?.message === 'string') {
      errors.push(resultado?.message)
    }
  }

  if (resultado?.error?.length > 0 || resultado?.status == 500) {
    smsTitle = 'No se pudo procesar el documento'
    if (typeof resultado?.message === 'string') {
      errors.push(resultado?.message)
    }
  }

  if (resultado?.status === 500) {
    smsTitle = resultado?.error ?? ''
    if (resultado?.body?.message?.observaciones) {
      if (Array.isArray(resultado?.body?.message?.observaciones)) {
        resultado?.body?.message?.observaciones.map(function (key) {
          errors.push(key)
        })
      } else if (!Array.isArray(resultado?.body?.message?.observaciones)) {
        const sms: any = resultado?.body?.message?.observaciones ?? ''
        errors.push(String(sms))
      }
    }

    if (resultado?.body?.message?.descripcionMsg) {
      if (Array.isArray(resultado?.body?.message?.descripcionMsg)) {
        resultado?.body?.message?.descripcionMsg.map(function (key) {
          errors.push(key)
        })
      } else if (!Array.isArray(resultado?.body?.message?.descripcionMsg)) {
        const sms: any = resultado?.body?.message?.descripcionMsg ?? ''
        errors.push(String(sms))
      }
    }

    if (resultado?.body?.body?.mensaje) {
      if (Array.isArray(resultado?.body?.body?.mensaje)) {
        resultado?.body?.body?.mensaje.map(function (key) {
          errors.push(key)
        })
      } else if (!Array.isArray(resultado?.body?.message)) {
        const sms: any = resultado?.body?.message ?? ''
        errors.push(String(sms))
      }
    }
  } else if (resultado?.statusCode === 400) {
    smsTitle = resultado?.error ?? ''
    if (resultado?.message?.length > 0) {
      errors = resultado?.message
    }
  }

  let mensajesCapturados: string[] = []

  if (!Array.isArray(errors)) {
    mensajesCapturados.push(errors)
  } else {
    for (const mensaje of errors) {
      let mensajeFormateado = mensaje.endsWith('.')
        ? mensaje.slice(0, -1)
        : mensaje

      if (mensajeFormateado.includes(']')) {
        mensajeFormateado = mensaje.replaceAll('[', '').replace(']', ': ')
      } else if (mensajeFormateado.includes('.')) {
        const mensaje = mensajeFormateado.split('.')
        //validar si tiene mas de 2
        if (mensaje.length > 2) {
          mensajeFormateado = mensaje.slice(2).join('.').replace('.', ': ')
        } else {
          mensajeFormateado = mensaje.slice(1).join('.').replace('.', ': ')
        }
        // mensajeFormateado = utilidades.getTextAfterLastDot(mensajeFormateado)
      }
      const palabras = mensajeFormateado.split(/(?=[A-Z][a-z])/)
      // Convertir cada palabra a minúsculas y agregar espacios entre palabras

      mensajeFormateado = palabras
        .map(function (palabra) {
          return palabra.toLowerCase()
        })
        .join(' ')
        //eliminar #/ y cuando encuentre un / solo dejar un espacio
        .replaceAll(/#/g, '')
        .replaceAll(/ \/ /g, ' ')
        .replaceAll(/\//g, ' ')
      mensajeFormateado = mensajeFormateado.toLowerCase()
      mensajeFormateado =
        mensajeFormateado.charAt(0).toUpperCase() + mensajeFormateado.slice(1)
      mensajesCapturados.push(mensajeFormateado)
    }
  }

  mensajesCapturados = eliminarObjetosRepetidos(mensajesCapturados)

  mensajesCapturados = mensajesCapturados.map((mensaje) => {
    let mensajeTraducido = messagesSvMiddleware[mensaje?.trim()]
    if (mensajeTraducido === undefined) {
      mensajeTraducido = buscarMensaje(mensaje)
    }
    return mensajeTraducido ? mensajeTraducido : mensaje
  })

  return {
    smsTitle,
    errors: mensajesCapturados,
  }
}

function eliminarObjetosRepetidos(array: string[]): string[] {
  return array.filter((item, index) => array.indexOf(item) === index)
}

function buscarMensaje(texto: string): string | undefined {
  const textoBuscado = dejarUnEspacio(texto.toLowerCase())
  for (const clave in messagesSvMiddleware) {
    if (dejarUnEspacio(textoBuscado.toLowerCase()).includes(clave)) {
      return messagesSvMiddleware[clave]
    }
  }
  return undefined
}

function dejarUnEspacio(texto: string): string {
  // Utilizamos una expresión regular para reemplazar múltiples espacios en blanco con un solo espacio
  return texto.replace(/\s+/g, ' ')
}

function isOperationCondition(condition: EOperationContitionSLV): string {
  let conditionOp = 'Contado'
  switch (condition) {
    case EOperationContitionSLV.Contado:
      conditionOp = 'Contado'
      break
    case EOperationContitionSLV.Credito:
      conditionOp = 'A crédito'
      break
    case EOperationContitionSLV.Otro:
      conditionOp = 'Otro'
      break
  }
  return conditionOp
}

function onDetectConditionPay(methodPay): EOperationContitionSLV {
  if (methodPay.length === 1) {
    for (let i = 0; i < methodPay.length; i++) {
      if (Number(methodPay[i]['codigo']) === EFormasPago.credito) {
        return EOperationContitionSLV.Credito
      } else if (Number(methodPay[i]['codigo']) === EFormasPago.efectivo) {
        return EOperationContitionSLV.Contado
      } else if (
        Number(methodPay[i]['codigo']) !== EFormasPago.credito &&
        Number(methodPay[i]['codigo']) !== EFormasPago.efectivo
      ) {
        return EOperationContitionSLV.Otro
      }
    }
  } else if (methodPay.length > 1) {
    return EOperationContitionSLV.Otro
  }
}

function adapterConsumidorFinal(data: any): TS_InvoiceConsumidorFinal {
  const {
    empresa,
    ambienteAc,
    autorizacionDocumento,
    numeroControlDte,
    tipoEstablecimiento,
    codTipoEstablecimiento,
    ciudadEmisor,
    ciudadReceptor,
    cliente,
    products,
    fecha,
    hora,
    totales,
    usuario,
    tipoModelo = 1,
    tipoOperacion = 1,
    tipoContingencia = null,
    motivoContin = null,
    identificador,
    infoTributario,
    pagos,
    condicionOperacion,
    observaciones,
  } = data
  consoleService.log('adapterConsumidorFinal', data)

  const sumaVentaAgravada = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.ventaGravada
    }
    return acc
  }, 0)

  const totalExento = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.exento) {
      acc += item.ventaExenta
    }
    return acc
  }, 0)

  const sumaVentaNoSuj = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.noSujeto) {
      acc += item.ventaNoSuj
    }
    return acc
  }, 0)

  const totalDescuNoSuj = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.noSujeto) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescuGravado = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescuExento = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.exento) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const ivaTotal = products.reduce((acc, item) => {
    if (item.ivaItem) {
      acc += item.ivaItem
    }
    return acc
  }, 0)

  let receptor = null
  const tipoDocumentoCliente = cliente?.tipoIdentificacion?.trim() || null
  let noDocumentoCliente = cliente?.identificacion || null

  const totalDescu = utilidades.formatDecimal(
    totalDescuNoSuj + totalDescuGravado + totalDescuExento,
    2,
  )

  if (cliente?.codigo !== 0) {
    if (tipoDocumentoCliente == '13') {
      if (!noDocumentoCliente.includes('-')) {
        noDocumentoCliente =
          noDocumentoCliente.substring(0, noDocumentoCliente.length - 1) +
          '-' +
          noDocumentoCliente.substring(
            noDocumentoCliente.length - 1,
            noDocumentoCliente.length,
          )
      }
    }

    let codActividad = null
    let descActividad = null

    if (
      infoTributario?.codigoTributario !== null &&
      infoTributario?.descripcion !== null
    ) {
      codActividad =
        utilidades.zeroFill(infoTributario?.codigoTributario, 5, 'left') ?? null
      descActividad = infoTributario?.descripcion ?? null
    }

    receptor = {
      tipoDocumento: tipoDocumentoCliente,
      numDocumento: noDocumentoCliente,
      nrc: getNrc(cliente?.nrc),
      nombre: cliente?.nombres,
      codActividad: codActividad ?? null,
      descActividad: descActividad ?? null,
      direccion: {
        departamento:
          utilidades.zeroFill(
            ciudadReceptor?.codigoProvinciaOrigen,
            2,
            'left',
          ) ?? '00',
        municipio:
          utilidades.zeroFill(ciudadReceptor?.codigoOrigen, 2, 'left') ?? '00',
        complemento: getDireccionComplemente(cliente?.direccion),
      },
      telefono: getContacto(cliente?.telefono),
      correo: getEmail(cliente?.email),
    }
  }

  if (!receptor) {
    receptor = {
      tipoDocumento: null,
      numDocumento: null,
      nrc: getNrc(cliente?.nrc),
      nombre: cliente?.nombres,
      codActividad: null,
      descActividad: null,
      direccion: null,
      telefono: null,
      correo: null,
    }
  }

  const pagosDte = []

  for (let index = 0; index < pagos.length; index++) {
    pagosDte.push({
      codigo: pagos[index].codigo ?? '01',
      montoPago: utilidades.formatDecimal(pagos[index].montoPago, 2),
      referencia: pagos[index].referencia ?? null,
      plazo: pagos[index].plazo ?? null,
      periodo: pagos[index].periodo ?? null,
    })
  }

  let extension: any = extensionData
  if (observaciones !== '') {
    extension.observaciones = observaciones ?? ''
  }
  if (usuario && usuario.codigo) {
    extension.nombEntrega = getNombreUsuario(usuario)
  }
  if (Object.keys(extension).length === 0) {
    extension = null
  }

  const descuentos = utilidades.formatDecimal(totalDescu, 2)
  const totalExenta = utilidades.formatDecimal(totalExento, 2)
  const totalGravada = utilidades.formatDecimal(sumaVentaAgravada, 2)
  const totalNoSujeto = utilidades.formatDecimal(sumaVentaNoSuj, 2)
  const totalVentas = utilidades.formatDecimal(
    totalNoSujeto + totalExento + totalGravada,
    2,
  )

  const cuerpoDocumento = products.map((prod) => {
    prod.codigo = prod.codigoBarras
    prod.ventaExenta =  utilidades.formatDecimal(prod.ventaExenta,2)
    prod.ventaGravada =  utilidades.formatDecimal(prod.ventaGravada,2)
    prod.ivaItem = utilidades.formatDecimal(prod.ivaItem , 4)
    prod.montoDescu = utilidades.formatDecimal(prod.montoDescu , 4)
    prod.precioUni = Number(prod.precioUni)
    return prod
  })

  const factura: TS_InvoiceConsumidorFinal = {
    idEnvio: 1,
    consumidorFinal: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 1,
          ambiente: ambienteAc,
          tipoDte: autorizacionDocumento?.codigoTIC ?? '01',
          numeroControl: 'DTE-' + numeroControlDte.content,
          codigoGeneracion: identificador.toUpperCase(),
          tipoModelo,
          tipoOperacion,
          tipoContingencia,
          motivoContin,
          fecEmi: fecha,
          horEmi: hora,
          tipoMoneda: EMonedaStandar.USD,
        },
        documentoRelacionado: null,
        emisor: {
          nit: empresa?.ruc ?? '',
          nrc: empresa?.nrc ?? '',
          nombre: empresa?.nombre ?? '',
          codActividad:
            utilidades.zeroFill(
              autorizacionDocumento?.codTributarioTipoDeNegocio?.toString() ??
                '',
              5,
              'left',
            ) ?? '',
          descActividad: autorizacionDocumento?.descTipoDeNegocio ?? '',
          nombreComercial: empresa.comercial,
          tipoEstablecimiento:
            (tipoEstablecimiento !== null ? codTipoEstablecimiento : '') ?? '', //De donde se tomara?
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadEmisor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadEmisor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: empresa.direccion,
          },
          telefono: getContacto(empresa.telefono),
          correo: getEmail(empresa.email),
          ...getEstabPuntoMH(numeroControlDte.content),
        },
        receptor: receptor,
        otrosDocumentos: null,
        ventaTercero: null,
        cuerpoDocumento: cuerpoDocumento,
        resumen: {
          totalNoSuj: utilidades.formatDecimal(totalNoSujeto, 2),
          totalExenta: utilidades.formatDecimal(totalExenta, 2),
          totalGravada: utilidades.formatDecimal(totalGravada, 2),
          subTotalVentas: utilidades.formatDecimal(
            utilidades.formatDecimal(
              totalExento + totalNoSujeto + totalGravada,
              2,
            ),
            2,
          ),
          descuNoSuj: 0,
          descuExenta: 0,
          descuGravada: 0,
          porcentajeDescuento: 0,
          totalDescu: descuentos,
          tributos: [],
          subTotal: utilidades.formatDecimal(totalVentas, 2),
          ivaRete1: utilidades.formatDecimal(totales.retenerIva, 2) ?? 0.0,
          reteRenta: utilidades.formatDecimal(totales.retenerRenta, 2),
          montoTotalOperacion: utilidades.formatDecimal(totalVentas, 2),
          totalNoGravado: 0,
          totalPagar: utilidades.formatDecimal(totales.total, 2),
          totalLetras: utilidades.numeroALetras(totales.total, {
            singular: 'DOLAR',
            plural: 'DÓLARES',
            centSingular: 'CENTAVOS',
            centPlural: 'CENTAVOS',
          }),
          totalIva: utilidades.formatDecimal(ivaTotal, 2),
          saldoFavor: 0,
          condicionOperacion: condicionOperacion ?? 1,
          pagos: totales.total === 0 ? null : pagosDte,
          numPagoElectronico: '',
        },
        extension: extension,
        apendice: [
          {
            campo: 'CAJERO',
            etiqueta: 'CAJERO',
            valor: usuario.nombres + ' ' + usuario.apellidos,
          },
        ],
      },
    },
  }
  console.log('factura', factura)
  return factura
}

function adapterRetencion(data: any) {
  const {
    empresa,
    ambienteAc,
    autorizacionDocumento,
    numeroControlDte,
    tipoEstablecimiento,
    codTipoEstablecimiento,
    ciudadEmisor,
    ciudadReceptor,
    cliente,
    products,
    formattedDate,
    formattedTime,
    infoTributario,
  } = data

  const totalMontoSujetoGrav = products.reduce((acc, item) => {
    acc += item.montoSujetoGrav
    return acc
  }, 0)
  //ivaRetenido
  const totalIvaRetenido = products.reduce((acc, item) => {
    acc += item.ivaRetenido
    return acc
  }, 0)

  let noDocumentoCliente = cliente.identificacion
  if (cliente.tipoIdentificacion == '13') {
    if (!noDocumentoCliente.includes('-')) {
      noDocumentoCliente =
        noDocumentoCliente.substring(0, noDocumentoCliente.length - 1) +
        '-' +
        noDocumentoCliente.substring(
          noDocumentoCliente.length - 1,
          noDocumentoCliente.length,
        )
    }
  }

  const receptor = {
    tipoDocumento: cliente.tipoIdentificacion,
    numDocumento: noDocumentoCliente,
    nrc: getNrc(cliente?.nrc),
    nombre: cliente.nombre,
    codActividad: `${
      utilidades.zeroFill(infoTributario?.codigoTributario, 5, 'left') ?? ''
    }`,
    descActividad: infoTributario?.descripcion ?? '',
    nombreComercial: cliente.razonComercial,
    direccion: {
      departamento:
        utilidades.zeroFill(
          ciudadReceptor?.codigoProvinciaOrigen ?? '00',
          2,
          'left',
        ) ?? '00',
      municipio:
        utilidades.zeroFill(ciudadReceptor?.codigoOrigen ?? '00', 2, 'left') ??
        '00',
      complemento: cliente.direccion,
    },
    telefono: getContacto(cliente.telefono),
    correo: getEmail(cliente?.email) ?? '',
  }

  const factura = {
    idEnvio: 1,
    comprobanteRetencion: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 1,
          ambiente: ambienteAc,
          tipoDte: '07',
          numeroControl: 'DTE-' + numeroControlDte.content,
          codigoGeneracion: uuidv4().toUpperCase(),
          tipoModelo: 1,
          tipoOperacion: 1,
          tipoContingencia: null,
          motivoContin: null,
          fecEmi: formattedDate,
          horEmi: formattedTime,
          tipoMoneda: EMonedaStandar.USD,
        },
        emisor: {
          nit: empresa?.ruc ?? '',
          nrc: empresa?.nrc ?? '',
          nombre: empresa.nombre,
          codActividad:
            utilidades.zeroFill(
              autorizacionDocumento?.codTributarioTipoDeNegocio?.toString() ??
                '',
              5,
              'left',
            ) ?? '',
          descActividad: autorizacionDocumento?.descTipoDeNegocio ?? '',
          nombreComercial: empresa.comercial,
          tipoEstablecimiento:
            (tipoEstablecimiento !== null ? codTipoEstablecimiento : '') ?? '',
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadEmisor?.codigoProvinciaOrigen ?? '00',
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(
                ciudadEmisor?.codigoOrigen ?? '',
                2,
                'left',
              ) ?? '00',
            complemento: empresa.direccion,
          },
          telefono: getContacto(empresa.telefono),
          codigoMH: null,
          codigo: null,
          puntoVentaMH: 'null',
          puntoVenta: 'null',
          correo: getEmail(empresa.email),
          ...getEstabPuntoMH(numeroControlDte.content),
        },
        receptor: receptor,
        cuerpoDocumento: products,
        resumen: {
          totalSujetoRetencion: utilidades.formatDecimal(
            totalMontoSujetoGrav,
            2,
          ),
          totalIVAretenido: utilidades.formatDecimal(totalIvaRetenido, 2),
          totalIVAretenidoLetras: utilidades.numeroALetras(totalIvaRetenido, {
            singular: 'DOLAR',
            plural: 'DÓLARES',
            centSingular: 'CENTAVOS',
            centPlural: 'CENTAVOS',
          }),
        },
        extension: null,
        apendice: null,
      },
    },
  }
  return factura
}

async function adapterCreditoFiscal(data: any) {
  const {
    empresa,
    ambienteAc,
    autorizacionDocumento,
    numeroControlDte,
    tipoEstablecimiento,
    codTipoEstablecimiento,
    ciudadEmisor,
    ciudadReceptor,
    cliente,
    products,
    fecha,
    hora,
    totales,
    usuario,
    infoTributario,
    tipoDocumento,
    tipoModelo = 1,
    tipoOperacion = 1,
    tipoContingencia = null,
    motivoContin = null,
    identificador,
    pagos,
    condicionOperacion,
    observaciones,
    numeroOrden,
  } = data

  console.log('adapterCreditoFiscal ---------> ', data)

  const detalle = FormatProductsElectronicInvoiceSVCCF(
    products,
    4,
  )
  console.log('---->detalle', detalle)

  const totalGravada = detalle.reduce((acc, item) => {
    if (item?.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.ventaGravada
    }
    return acc
  }, 0)

  const totalExenta = detalle.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.exento) {
      acc += item.ventaExenta
    }
    return acc
  }, 0)

  const totalNoSuj = detalle.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.noSujeto) {
      acc += item.ventaNoSuj
    }
    return acc
  }, 0)

  const totalDescuNoSuj = detalle.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.noSujeto) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescuExenta = detalle.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.exento) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescuGravado = detalle.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const ivaTotal = detalle.reduce((sum, next) => {
    if (next.codigoImpuesto === ETipoItemImpuesto.gravado) {
      return sum + next.ivaItem
    } else {
      return sum
    }
  }, 0)

  let receptor = null

  const totalDescu = utilidades.formatDecimal(
    totalDescuNoSuj + totalDescuGravado + totalDescuExenta,
    2,
  )

  console.log('----------->totalDescuNoSuj', totalDescuNoSuj)
  console.log('----------->totalDescuGravado', totalDescuGravado)
  console.log('----------->totalDescuExenta', totalDescuExenta)
  console.log('----------->totalDescu', totalDescu)

  if (cliente?.codigo !== 0) {
    receptor = {
      nit: cliente.identificacion ?? '',
      nrc: getNrc(cliente?.nrc),
      nombre: cliente.nombres,
      codActividad: `${
        utilidades.zeroFill(infoTributario?.codigoTributario, 5, 'left') ?? ''
      }`,
      descActividad: infoTributario?.descripcion ?? '',
      nombreComercial: cliente.nombreComercial,
      direccion: {
        departamento:
          utilidades.zeroFill(
            ciudadReceptor?.codigoProvinciaOrigen,
            2,
            'left',
          ) ?? '00',
        municipio:
          utilidades.zeroFill(ciudadReceptor?.codigoOrigen, 2, 'left') ?? '00',
        complemento: cliente.direccion,
      },
      telefono: getContacto(cliente?.telefono),
      correo: getEmail(cliente?.email) ?? '',
    }
  }

  const pagosDte = []
  for (let index = 0; index < pagos.length; index++) {
    pagosDte.push({
      codigo: pagos[index].codigo ?? '01',
      montoPago: utilidades.formatDecimal(pagos[index].montoPago, 2),
      referencia: pagos[index].referencia ?? null,
      plazo: pagos[index].plazo ?? null,
      periodo: pagos[index].periodo ?? null,
    })
  }

  const montoTotalOperacion = utilidades.formatDecimal(
    totalGravada + totalExenta + totalNoSuj + ivaTotal ,
    2,
  )

  const descuentos = utilidades.formatDecimal(totalDescu, 2)

  let extension: any = extensionData

  if (observaciones !== '') {
    extension.observaciones = observaciones ?? ''
  }
  if (usuario && usuario.codigo) {
    extension.nombEntrega = getNombreUsuario(usuario)
  }
  if (Object.keys(extension).length === 0) {
    extension = null
  }

  let apendice: any = null
  const apendiceAdd: any = []
  if (numeroOrden !== undefined) {
    apendiceAdd.push({
      campo: 'ORDEN DE COMPRA',
      etiqueta: 'ordenCompra',
      valor: numeroOrden,
    })
  }

  if (apendiceAdd.length > 0) {
    apendice = apendiceAdd
  }

  const cuerpoDocumento = detalle.map((prod) => {
    prod.codigo = prod.codigoBarras
    prod.ventaExenta = parseFloat(prod.ventaExenta.toFixed(4))
    prod.ventaGravada = parseFloat(prod.ventaGravada.toFixed(4))
    prod.montoDescu = parseFloat(prod.montoDescu.toFixed(4))
    prod.precioUni = parseFloat(prod.precioUni.toFixed(4))
    return prod
  })

  const factura = {
    idEnvio: 1,
    comprobanteCreditoFiscal: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 3,
          ambiente: ambienteAc,
          tipoDte: tipoDocumento,
          numeroControl: 'DTE-' + numeroControlDte.content,
          codigoGeneracion: identificador.toUpperCase(),
          tipoModelo,
          tipoOperacion,
          tipoContingencia,
          motivoContin,
          fecEmi: fecha,
          horEmi: hora,
          tipoMoneda: EMonedaStandar.USD,
        },
        documentoRelacionado: null,
        emisor: {
          nit: empresa?.ruc ?? '',
          nrc: empresa?.nrc ?? '',
          nombre: empresa.nombre,
          codActividad:
            utilidades.zeroFill(
              autorizacionDocumento?.codTributarioTipoDeNegocio?.toString() ??
                '',
              5,
              'left',
            ) ?? '',
          descActividad: autorizacionDocumento?.descTipoDeNegocio ?? '',
          nombreComercial: empresa.comercial,
          tipoEstablecimiento:
            (tipoEstablecimiento !== null ? codTipoEstablecimiento : '') ?? '',
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadEmisor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadEmisor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: empresa.direccion,
          },
          telefono: getContacto(empresa.telefono),
          correo: getEmail(empresa.email),
          ...getEstabPuntoMH(numeroControlDte.content),
        },
        receptor: receptor,
        otrosDocumentos: null,
        ventaTercero: null,
        cuerpoDocumento: cuerpoDocumento,
        resumen: {
          totalNoSuj: utilidades.formatDecimal(totalNoSuj, 2) ?? 0.0,
          totalExenta: utilidades.formatDecimal(totalExenta, 2),
          totalGravada: utilidades.formatDecimal(totalGravada, 2),
          subTotalVentas: utilidades.formatDecimal(
            totalExenta + totalGravada + totalNoSuj,
            2,
          ),
          descuNoSuj: 0.0,
          descuExenta: 0,
          descuGravada: 0,
          porcentajeDescuento: 0,
          totalDescu: descuentos,
          tributos:
            totalGravada > 0
              ? [
                  {
                    codigo: '20',
                    descripcion: 'IVA Débito',
                    valor: utilidades.formatDecimal(ivaTotal, 2),
                  },
                ]
              : null,
          subTotal: utilidades.formatDecimal(
            Number(totalExenta + totalGravada + totalNoSuj),
            2,
          ),
          ivaPerci1: 0.0,
          ivaRete1: utilidades.formatDecimal(totales.retenerIva, 2) ?? 0.0,
          reteRenta: utilidades.formatDecimal(totales.retenerRenta, 2),
          montoTotalOperacion: montoTotalOperacion,
          totalNoGravado: 0.0,
          totalPagar: utilidades.formatDecimal(totales.total, 2),
          totalLetras: utilidades.numeroALetras(totales.total, {
            singular: 'DOLAR',
            plural: 'DÓLARES',
            centSingular: 'CENTAVOS',
            centPlural: 'CENTAVOS',
          }),
          saldoFavor: 0.0,
          condicionOperacion: condicionOperacion ?? 1,
          pagos: totales.total === 0 ? null : pagosDte,
          numPagoElectronico: '',
        },
        extension: extension,
        apendice: apendice,
      },
    },
  }
  console.log('factura', factura)
  return factura
}

function adapterExportar(data: any): any {
  const {
    empresa,
    ambienteAc,
    autorizacionDocumento,
    numeroControlDte,
    tipoEstablecimiento,
    codTipoEstablecimiento,
    ciudadEmisor,
    ciudadReceptor,
    cliente,
    products,
    totales,
    tipoDocumento = '11',
    flete,
    seguro,
    usuario,
    subtotal,
    codTipoId,
    activities,
    identificador,
    pagos,
    condicionOperacion,
    fecha,
    hora,
    observaciones,
  } = data
  console.log('adapterExportar', data)

  const totalDescuGravado = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescuNoGravado = products.reduce((acc, item) => {
    if (item.codigoImpuesto !== ETipoItemImpuesto.gravado) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescu = utilidades.formatDecimal(
    totalDescuGravado + totalDescuNoGravado,
    2,
  )

  const totalGravada: number = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.ventaGravada
    }
    return acc
  }, 0)

  const totalNoGravada: number = products.reduce((acc, item) => {
    if (item.codigoImpuesto !== ETipoItemImpuesto.gravado) {
      acc += item.ventaGravada
    }
    return acc
  }, 0)

  let receptor = null

  if (cliente?.codigo !== 0) {
    let noDocumentoCliente = cliente.identificacion
    if (cliente.tipoIdentificacion == '13') {
      if (!noDocumentoCliente.includes('-')) {
        noDocumentoCliente =
          noDocumentoCliente.substring(0, noDocumentoCliente.length - 1) +
          '-' +
          noDocumentoCliente.substring(
            noDocumentoCliente.length - 1,
            noDocumentoCliente.length,
          )
      }
    }

    receptor = {
      tipoDocumento: cliente?.tipoIdentificacion,
      numDocumento: noDocumentoCliente,
      nombre: cliente.nombres,
      nombreComercial:
        cliente.nombreComercial === '' ? null : cliente.nombreComercial,
      descActividad:
        activities.find((act) => act.codigo === Number(cliente.tipoNegocio))
          ?.descripcion ?? 'Ninguna',
      complemento: cliente.direccion,
      codPais: ciudadReceptor?.codigoPaisInternacional + '',
      nombrePais: ciudadReceptor?.nombrePais,
      tipoPersona: 1,
      telefono: getContacto(cliente?.telefono),
      correo: getEmail(cliente?.email),
    }
  }

  const montoTotalOperacion = totales.total

  const pagosDte = []
  for (let index = 0; index < pagos.length; index++) {
    pagosDte.push({
      codigo: pagos[index].codigo ?? '01',
      montoPago: utilidades.formatDecimal(pagos[index].montoPago, 2),
      referencia: pagos[index].referencia ?? null,
      plazo: pagos[index].plazo ?? null,
      periodo: pagos[index].periodo ?? null,
    })
  }

  let extension: any = extensionData

  if (observaciones !== '') {
    extension.observaciones = observaciones ?? ''
  }
  if (usuario && usuario.codigo) {
    extension.nombEntrega = getNombreUsuario(usuario)
  }
  if (Object.keys(extension).length === 0) {
    extension = null
  }

  const _totalGravada = utilidades.formatDecimal(totalGravada, 2)
  const _totalNoGravada = utilidades.formatDecimal(totalNoGravada, 2)

  const cuerpoDocumento = products.map((prod) => {
    prod.codigo = prod.codigoBarras
    return prod
  })

  const factura: any = {
    idEnvio: 1,
    facturaExportacion: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 1,
          ambiente: ambienteAc,
          tipoDte: tipoDocumento ?? '11',
          numeroControl: 'DTE-' + numeroControlDte.content,
          codigoGeneracion: identificador.toUpperCase(),
          tipoModelo: 1,
          tipoOperacion: 1,
          tipoContingencia: null,
          motivoContigencia: null,
          fecEmi: fecha,
          horEmi: hora,
          tipoMoneda: EMonedaStandar.USD,
        },
        emisor: {
          nit: empresa?.ruc ?? '',
          nrc: empresa?.nrc ?? '',
          nombre: empresa?.nombre ?? '',
          codActividad:
            utilidades.zeroFill(
              autorizacionDocumento?.codTributarioTipoDeNegocio?.toString() ??
                '',
              5,
              'left',
            ) ?? '',
          descActividad: autorizacionDocumento?.descTipoDeNegocio ?? '',
          nombreComercial: empresa.comercial,
          tipoEstablecimiento:
            (tipoEstablecimiento !== null ? codTipoEstablecimiento : '') ?? '', //De donde se tomara?
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadEmisor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadEmisor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: empresa.direccion,
          },
          telefono: getContacto(empresa.telefono),
          correo: getEmail(empresa.email),
          tipoItemExpor: empresa?.tipoItemExporta,
          recintoFiscal:
            empresa?.recintoFiscal.length > 0 &&
            empresa?.recintoFiscal != 'NULL'
              ? empresa?.recintoFiscal
              : null,
          regimen:
            empresa?.regimen.length > 0 && empresa?.regimen.length != 'NULL'
              ? empresa?.regimen
              : null,
          ...getEstabPuntoMH(numeroControlDte.content),
        },
        receptor: receptor,
        otrosDocumentos: null,
        ventaTercero: null,
        cuerpoDocumento: cuerpoDocumento,
        resumen: {
          descuento: totalDescu,
          porcentajeDescuento: 0,
          totalPagar: utilidades.formatDecimal(totales.total, 2),
          totalNoGravado: _totalNoGravada,
          pagos: totales.total === 0 ? null : pagosDte,
          codIncoterms: null,
          descIncoterms: null,
          flete: flete ?? 0,
          seguro: seguro ?? 0,
          numPagoElectronico: '',
          totalGravada: _totalGravada,
          totalDescu: totalDescu,
          montoTotalOperacion: utilidades.formatDecimal(montoTotalOperacion, 2),
          totalLetras: utilidades.numeroALetras(montoTotalOperacion, {
            plural: 'DÓLARES',
            centSingular: 'CENTAVOS',
            centPlural: 'CENTAVOS',
          }),
          condicionOperacion: condicionOperacion ?? 1,
          observaciones: observaciones
            ? truncateText(observaciones, 500)
            : null,
        },
        apendice: [
          {
            campo: 'Datos del vendedor',
            etiqueta: 'Nombre',
            valor: usuario.nombres + ' ' + usuario.apellidos,
          },
        ],
      },
    },
  }

  return factura
}

function adapterSujetoExcluido(data: any, precision = 2) {
  const {
    empresa,
    ambienteAc,
    autorizacionDocumento,
    numeroControlDte,
    ciudadEmisor,
    ciudadReceptor,
    cliente,
    products,
    infoTributario,
    pagos,
    identificador,
    totales,
    condicionOperacion,
    modeloFacturacion,
    tipoTransmision,
    fecha,
    hora,
  } = data
  consoleService.log('adapterSujetoExcluido', data)
  consoleService.log('totales', totales)

  const productsParser = products.map((prod) => {
    const compra = utilidades.formatDecimal(
      prod.cantidad * prod.precioUni - prod.montoDescu,
      precision,
    )
    const prodParser = {
      ...prod,
      compra,
    }
    delete prodParser.ivaItem
    delete prodParser.tributos
    delete prodParser.noGravado
    delete prodParser.psv
    delete prodParser.ventaGravada
    delete prodParser.ventaExenta
    delete prodParser.ventaNoSuj
    delete prodParser.numeroDocumento
    delete prodParser.codTributo
    prodParser.codigo = prod.codigoBarras
    delete prodParser.codigoBarras
    return prodParser
  })

  //calcular descuento con iva
  let descuentoConIva = totales.descuentoGloabal
  descuentoConIva = utilidades.formatDecimal(descuentoConIva, 6)

  const totalDescuentoLineaProd = products.reduce((acc, item) => {
    acc += item.montoDescu
    return acc
  }, 0)

  const totalCompra = productsParser.reduce((acc, item) => {
    acc += item.compra
    return acc
  }, 0)

  const pagosDte = []
  for (let index = 0; index < pagos.length; index++) {
    pagosDte.push({
      codigo: pagos[index].codigo ?? '01',
      montoPago: utilidades.formatDecimal(pagos[index].montoPago, 2),
      referencia: pagos[index].referencia ?? null,
      plazo: pagos[index].plazo ?? null,
      periodo: pagos[index].periodo ?? null,
    })
  }

  const factura = {
    idEnvio: 1,
    sujetoExcluido: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 1,
          ambiente: ambienteAc,
          tipoDte: '14',
          numeroControl: 'DTE-' + numeroControlDte.content,
          codigoGeneracion: identificador.toUpperCase(),
          tipoModelo: modeloFacturacion,
          tipoOperacion: tipoTransmision,
          tipoContingencia: null,
          motivoContin: null,
          fecEmi: fecha,
          horEmi: hora,
          tipoMoneda: EMonedaStandar.USD,
        },
        emisor: {
          nit: empresa?.ruc ?? '',
          nrc: empresa?.nrc ?? '',
          nombre: empresa.nombre,
          codActividad:
            utilidades.zeroFill(
              autorizacionDocumento?.codTributarioTipoDeNegocio?.toString() ??
                '',
              5,
              'left',
            ) ?? null,
          descActividad: autorizacionDocumento?.descTipoDeNegocio ?? null,
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadEmisor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadEmisor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: empresa.direccion,
          },
          telefono: getContacto(empresa.telefono),
          correo: getEmail(empresa.email),
          ...getEstabPuntoMH(numeroControlDte.content),
        },
        sujetoExcluido: {
          tipoDocumento: cliente.tipoIdentificacion,
          numDocumento: cliente.identificacion
            ? cliente.identificacion?.replaceAll('-', '')
            : '',
          nombre: cliente.nombres,
          codActividad: `${
            utilidades.zeroFill(infoTributario?.codigoTributario, 5, 'left') ??
            ''
          }`,
          descActividad: infoTributario?.descripcion ?? '',
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadReceptor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadReceptor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: cliente.direccion,
          },
          telefono: getContacto(cliente?.telefono) ?? '',
          correo: getEmail(cliente?.email) ?? '',
        },
        cuerpoDocumento: productsParser,
        resumen: {
          totalCompra: utilidades.formatDecimal(totalCompra, 2),
          descu: 0.0,
          totalDescu: utilidades.formatDecimal(
            Number(descuentoConIva) + Number(totalDescuentoLineaProd),
            2,
          ),
          subTotal: utilidades.formatDecimal(
            totalCompra - Number(descuentoConIva),
            2,
          ),
          ivaRete1: utilidades.formatDecimal(totales.retenerIva, 2) ?? 0.0,
          reteRenta: utilidades.formatDecimal(totales.retenerRenta, 2),
          totalPagar: utilidades.formatDecimal(totales.total, 2),
          totalLetras: utilidades.numeroALetras(Number(totales.total), {
            singular: 'DOLAR',
            plural: 'DÓLARES',
            centSingular: 'CENTAVOS',
            centPlural: 'CENTAVOS',
          }),
          condicionOperacion: condicionOperacion ?? 1,
          pagos: pagosDte,
          observaciones: '',
        },
        apendice: null,
      },
    },
  }

  return factura
}

async function adapterNotaDebito(data: any): Promise<TS_DebitNote> {
  const {
    empresa,
    ambienteAc,
    autorizacionDocumento,
    numeroControlDte,
    tipoEstablecimiento,
    codTipoEstablecimiento,
    ciudadEmisor,
    ciudadReceptor,
    cliente,
    products,
    totales,
    usuario,
    formattedDateOrigin,
    datosEdicion,
    infoTributario,
    tipoDocumentoRelacionado,
    fecha,
    hora,
    observaciones,
  } = data
  const subtotal = utilidades.formatDecimal(totales.subtotal, 2)
  let ivaPorc = await VentasService.getIva(
    DateUtils.dateToString(new Date(), formatoFechasApi),
    null,
  )

  ivaPorc = ivaPorc / 100 + 1
  const subtotalIva = utilidades.formatDecimal(subtotal / (1 / ivaPorc), 2)
  const totalGravada = products.reduce((acc, item) => {
    acc += item.ventaGravada
    return acc
  }, 0)

  let extension: any = {
    nombEntrega: null,
    docuEntrega: null,
    nombRecibe: null,
    docuRecibe: null,
    observaciones: null,
  }
  if (observaciones !== '') {
    extension.observaciones = observaciones ?? ''
  }
  if (usuario && usuario.codigo) {
    extension.nombEntrega = getNombreUsuario(usuario)
  }
  if (Object.keys(extension).length === 0) {
    extension = null
  }

  const factura: TS_DebitNote = {
    idEnvio: 1,
    notaDebito: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 3,
          ambiente: ambienteAc,
          tipoDte: TiposComprobantesSLV.NotaDebito,
          numeroControl: 'DTE-' + numeroControlDte.content,
          codigoGeneracion: uuidv4().toUpperCase(),
          tipoModelo: 1,
          tipoOperacion: 1,
          tipoContingencia: null,
          motivoContin: null,
          fecEmi: fecha,
          horEmi: hora,
          tipoMoneda: EMonedaStandar.USD,
        },
        documentoRelacionado: [
          {
            tipoDocumento: tipoDocumentoRelacionado,
            tipoGeneracion: 2,
            numeroDocumento:
              datosEdicion?.documentoOrigen?.codigoGeneracion ?? '',
            fechaEmision: formattedDateOrigin ?? '',
          },
        ],
        emisor: {
          nit: empresa?.ruc ?? '',
          nrc: empresa?.nrc ?? '',
          nombre: empresa.nombre,
          codActividad:
            utilidades.zeroFill(
              autorizacionDocumento?.codTributarioTipoDeNegocio?.toString() ??
                '',
              5,
              'left',
            ) ?? '',
          descActividad: autorizacionDocumento?.descTipoDeNegocio ?? '',
          nombreComercial: empresa.comercial,
          tipoEstablecimiento:
            (tipoEstablecimiento !== null ? codTipoEstablecimiento : '') ?? '', //De donde se tomara?
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadEmisor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00', //'03', //De donde se tomara?
            municipio:
              utilidades.zeroFill(ciudadEmisor?.codigoOrigen, 2, 'left') ??
              '00', // '15', //De donde se tomara?
            complemento: empresa.direccion,
          },
          telefono: getContacto(empresa.telefono),
          correo: getEmail(empresa.email),
          ...getEstabPuntoMH(numeroControlDte.content),
        },
        receptor: {
          nit: cliente?.identificacion ?? '',
          nrc: getNrc(cliente?.nrc),
          nombre: cliente?.nombres ?? '',
          codActividad:
            utilidades.zeroFill(
              infoTributario?.codigoTributario.toString(),
              5,
              'left',
            ) ?? '',
          descActividad: infoTributario?.descripcion ?? '',
          nombreComercial: cliente?.nombreComercial ?? '',
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadReceptor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadReceptor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: cliente.direccion,
          },
          telefono: getContacto(cliente.telefono),
          correo: getEmail(cliente?.email) ?? '',
        },
        ventaTercero: null,
        cuerpoDocumento: products.map((prod) => {
          prod.numeroDocumento =
            datosEdicion?.documentoOrigen?.codigoGeneracion ?? ''
          prod.codigo = prod.codigoBarras
          delete prod.noGravado
          delete prod.ivaItem
          delete prod.psv
          delete prod.codigoBarras
          return prod
        }),
        resumen: {
          totalNoSuj: 0.0,
          totalExenta: 0.0,
          totalGravada: utilidades.formatDecimal(totalGravada, 2),
          subTotalVentas: utilidades.formatDecimal(totalGravada, 2),
          descuNoSuj: 0.0,
          descuExenta: 0.0,
          descuGravada: 0.0,
          totalDescu: 0.0,
          tributos: [
            {
              codigo: '20',
              descripcion: 'IVA Débito',
              valor: utilidades.formatDecimal(totales.iva, 2),
            },
          ],
          subTotal: utilidades.formatDecimal(totales.total - totales.iva, 2),
          ivaPerci1: 0.0,
          ivaRete1: 0.0,
          reteRenta: 0.0,
          montoTotalOperacion: utilidades.formatDecimal(subtotalIva, 2),
          totalLetras: utilidades.numeroALetras(subtotalIva, {
            singular: 'DOLAR',
            plural: 'DÓLARES',
            centSingular: 'CENTAVOS',
            centPlural: 'CENTAVOS',
          }),
          condicionOperacion: 1,
          numPagoElectronico: null,
        },
        extension: extension,
        apendice: null,
      },
    },
  }

  return factura
}

function adapterNotaCredito(data: any): TS_CreditNote {
  const {
    empresa,
    ambienteAc,
    autorizacion,
    numeroControlDte,
    tipoEstablecimiento,
    codTipoEstablecimiento,
    ciudadEmisor,
    ciudadReceptor,
    cliente,
    products,
    totales,
    usuario,
    documentoOrigen,
    infoTributario,
    subtotalIva,
    tipoModelo = 1,
    tipoOperacion = 1,
    tipoContingencia = null,
    motivoContin = null,
    identificador,
    condicionOperacion,
    fecha,
    hora,
    observaciones,
  } = data
  console.log('adapterNotaCredito--------->', data)

  console.log('products--------->', products)

  const totalGravada = products.reduce((acc, item) => {
    if (item?.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.ventaGravada
    }
    return acc
  }, 0)

  const totalExenta = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.exento) {
      acc += item.ventaExenta
    }
    return acc
  }, 0)

  const totalNoSuj = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.noSujeto) {
      acc += item.ventaNoSuj
    }
    return acc
  }, 0)


  const totalDescuNoSuj = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.noSujeto) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescuExenta = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.exento) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const totalDescuGravado = products.reduce((acc, item) => {
    if (item.codigoImpuesto === ETipoItemImpuesto.gravado) {
      acc += item.montoDescu
    }
    return acc
  }, 0)

  const ivaTotal = products.reduce((sum, next) => {
    if (next.codigoImpuesto === ETipoItemImpuesto.gravado) {
      return sum + next.ivaItem
    } else {
      return sum
    }
  }, 0)
  console.log('ivaTotal--------->', ivaTotal)

  const totalDescu = utilidades.formatDecimal(
    totalDescuNoSuj + totalDescuGravado + totalDescuExenta,
    2,
  )


  console.log('documentoOrigen', documentoOrigen)

  let formattedDateOrigin = ''
  if (documentoOrigen.fechaEmision.includes('/')) {
    const [documentDay, documentMonth, documentYear] =
      documentoOrigen.fechaEmision.split('/')
    formattedDateOrigin = `${documentYear}-${documentMonth}-${documentDay}`
  } else {
    const [documentDay, documentMonth, documentYear] =
      documentoOrigen.fechaEmision.split('-')
    formattedDateOrigin = `${documentYear}-${documentMonth}-${documentDay}`
  }
  console.log('formattedDateOrigin', formattedDateOrigin)
  let extension: any = {
    nombEntrega: null,
    docuEntrega: null,
    nombRecibe: null,
    docuRecibe: null,
    observaciones: null,
  }
  if (observaciones !== '') {
    extension.observaciones = observaciones ?? ''
  }
  if (usuario && usuario.codigo) {
    extension.nombEntrega = getNombreUsuario(usuario)
  }
  if (Object.keys(extension).length === 0) {
    extension = null
  }

  const cuerpoDocumento = products.map((prod) => {
    prod.codigo = prod.codigoBarras
    return {
      ...prod,
      numeroDocumento: documentoOrigen.codigoGeneracion,
    }
  });
  console.log('cuerpoDocumento--------->', cuerpoDocumento)

  const payload: TS_CreditNote = {
    idEnvio: 1,
    notaCredito: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 3,
          ambiente: ambienteAc,
          tipoDte: TiposComprobantesSLV.NotaCredito,
          numeroControl: 'DTE-' + numeroControlDte.content,
          codigoGeneracion: identificador.toUpperCase(),
          tipoModelo,
          tipoOperacion,
          tipoContingencia,
          motivoContin,
          fecEmi: fecha,
          horEmi: hora,
          tipoMoneda: EMonedaStandar.USD,
        },
        documentoRelacionado: [
          {
            tipoDocumento: documentoOrigen.tipoDoc,
            tipoGeneracion: documentoOrigen?.tipoGeneracion ?? 2, //De donde se sacara?
            numeroDocumento: documentoOrigen.codigoGeneracion,
            fechaEmision: formattedDateOrigin,
          },
        ],
        emisor: {
          nit: empresa?.ruc ?? '',
          nrc: empresa?.nrc ?? '',
          nombre: empresa.nombre,
          codActividad:
            utilidades.zeroFill(
              autorizacion?.codTributarioTipoDeNegocio.toString(),
              5,
              'left',
            ) ?? '',
          descActividad: autorizacion?.descTipoDeNegocio ?? '',
          nombreComercial: empresa.comercial,
          tipoEstablecimiento:
            (tipoEstablecimiento !== null ? codTipoEstablecimiento : '') ?? '',
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadEmisor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadEmisor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: empresa.direccion,
          },
          telefono: getContacto(empresa.telefono),
          correo: getEmail(empresa.email),
        },
        receptor: {
          nit: cliente.identificacion ?? '',
          nrc: getNrc(cliente?.nrc),
          nombre: cliente.nombres,
          codActividad: `${
            utilidades.zeroFill(infoTributario?.codigoTributario, 5, 'left') ??
            ''
          }`,
          descActividad: infoTributario?.descripcion ?? '',
          nombreComercial: cliente.nombreComercial,
          direccion: {
            departamento:
              utilidades.zeroFill(
                ciudadReceptor?.codigoProvinciaOrigen,
                2,
                'left',
              ) ?? '00',
            municipio:
              utilidades.zeroFill(ciudadReceptor?.codigoOrigen, 2, 'left') ??
              '00',
            complemento: cliente.direccion,
          },
          telefono: getContacto(cliente?.telefono),
          correo: getEmail(cliente?.email) ?? '',
        },
        ventaTercero: null,
        cuerpoDocumento: cuerpoDocumento,
        resumen: {
          totalNoSuj: utilidades.formatDecimal(totalNoSuj, 2),
          totalExenta: utilidades.formatDecimal(totalExenta, 2),
          totalGravada: utilidades.formatDecimal(totalGravada, 2),
          subTotalVentas: utilidades.formatDecimal(
            totalExenta + totalGravada + totalNoSuj,
            2,
          ),
          descuNoSuj: totalDescuNoSuj,
          descuExenta: totalDescuExenta,
          descuGravada: totalDescuGravado,
          totalDescu: totalDescu ,
          tributos:
            totalGravada != 0
              ? [
                  {
                    codigo: '20',
                    descripcion: 'IVA Débito',
                    valor: utilidades.formatDecimal(ivaTotal, 2),
                  },
                ]
              : null,
          subTotal: utilidades.formatDecimal(
            Number(
              totalGravada + totalExenta - totalDescuGravado - totalDescuExenta,
            ),
            2,
          ),
          ivaPerci1: 0.0,
          ivaRete1:
            Number(utilidades.formatDecimal(totales.retenerIva, 2)) ?? 0.0,
          reteRenta:
            Number(utilidades.formatDecimal(totales.retenerRenta, 2)) ?? 0.0,
          montoTotalOperacion: utilidades.formatDecimal(
            totalGravada +
              totalExenta +
              -totalDescu -
              totales.retenerIva -
              totales.retenerRenta +
              Number(utilidades.formatDecimal(totales.iva, 2)),
            2,
          ),
          totalLetras: utilidades.numeroALetras(
            Number(
              utilidades.formatDecimal(
                totalGravada +
                  totalExenta +
                  -totalDescu +
                  -totales.retenerIva -
                  totales.retenerRenta +
                  Number(utilidades.formatDecimal(totales.iva, 2)),
                2,
              ),
            ),
            {
              singular: 'DOLAR',
              plural: 'DÓLARES',
              centSingular: 'CENTAVOS',
              centPlural: 'CENTAVOS',
            },
          ),
          condicionOperacion: condicionOperacion,
        },
        extension: extension,
        apendice: null,
      },
    },
  }
  console.log('notaCRedito', payload)

  return payload
}

async function adapterContingencia(
  data: any,
  detalleDTE: TS_DetalleDTE[],
  motivoContingencia: TS_MotivoContingencia,
  numeroControl: string,
): Promise<TS_ConstingencySV> {
  const { empresa, local } = data

  let tipoEstablecimiento: TipoEstablecimientoOption | null = null
  let codTipoEstablecimiento: string = ''
  const tiposEstApi = await tipoEstablecimientoService.getTiposEstablecimientos(
    'Elija una opción',
    empresa?.codigoPais,
  )

  if (tiposEstApi.auto && tiposEstApi.auto.length > 0) {
    const selectedIndex = tiposEstApi.auto.findIndex(
      (select: TipoEstablecimientoOption) => {
        return select.codigo === local.tipo
      },
    )
    if (selectedIndex > -1) {
      tipoEstablecimiento = tiposEstApi.auto[selectedIndex]
      codTipoEstablecimiento = utilidades.zeroFill(
        tipoEstablecimiento.grupo,
        2,
        'left',
      )
    }
  }

  const payload: TS_ConstingencySV = {
    nit: empresa?.ruc ?? '',
    estado: 'try1',
    fechaHora: '',
    mensaje: '',
    selloRecibido: '',
    observaciones: ['try1'],
    contingencia: {
      nit: empresa?.ruc ?? '',
      activo: true,
      dteJson: {
        identificacion: {
          version: 3,
          ambiente: getAmbiente(empresa),
          codigoGeneracion: uuidv4().toUpperCase(),
          fTransmision: getCurrentFormattedDate(),
          hTransmision: getCurrentFormattedTime(),
        },
        emisor: {
          nit: empresa?.ruc ?? '',
          nombre: empresa.nombre,
          nombreResponsable: empresa.nombre,
          tipoDocResponsable: '36',
          numeroDocResponsable: empresa?.ruc ?? '',
          tipoEstablecimiento: codTipoEstablecimiento,
          telefono: getContacto(empresa.telefono),
          correo: getEmail(empresa.email),
          ...getEstabPuntoMHContingencia(numeroControl),
        },
        detalleDTE: detalleDTE,
        motivo: motivoContingencia,
      },
    },
  }

  return payload
}

function getCurrentFormattedDate() {
  const dateSv = new Date().toLocaleString('en-US', {
    timeZone: 'America/El_Salvador',
  })

  const currentDate = new Date(dateSv)
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

function getCurrentFormattedTime() {
  const dateSv = new Date().toLocaleString('en-US', {
    timeZone: 'America/El_Salvador',
  })

  const currentDate = new Date(dateSv)
  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}

function formatCellsIfDocummentIsDisabled(e) {
  if (e.rowType === 'data' && e.column.type === undefined) {
    const data = e.data as VentaListado
    if (data.estadoElectronico === 'ANULADO') {
      e.cellElement.classList = [...e.cellElement.classList, 'text-primary'] //push("text-danger")
    } else if (data.estadoElectronico === 'SIN AUTORIZAR') {
      e.cellElement.classList = [...e.cellElement.classList, 'text-danger'] //push("text-danger")
    }
  }
}

function isSendMailIconVisible(
  e,
  config?: { autorizadosEnviar: boolean; anuladosEnviar: boolean } | null,
) {
  const venta: VentaListado = e.row.data
  if (config !== null) {
    if (
      config?.autorizadosEnviar === true &&
      venta.estadoElectronico === 'AUTORIZADO'
    ) {
      return true
    }
    if (
      config?.anuladosEnviar === true &&
      venta.estadoElectronico === 'ANULADO'
    ) {
      return true
    }
  } else {
    return venta.estadoElectronico === 'AUTORIZADO'
  }
}

function isRemoveIconVisible(e) {
  const venta: VentaListado = e.row.data
  return venta.estadoElectronico === 'AUTORIZADO'
}

function isSendSriIconVisible(e) {
  const venta: VentaListado = e.row.data
  return (
    venta.estadoElectronico !== 'AUTORIZADO' &&
    venta.estadoElectronico !== 'ANULADO'
  )
}

function customizeSummaryText(data) {
  return (data.value ?? 0).toFixed(2)
}

function estadoElectronicoCell(cellData) {
  const getBadge = (status) => {
    switch (status) {
      case 'SIN AUTORIZAR':
        return 'danger'
      case 'AUTORIZADO':
        return 'success'
      case 'N/A':
      case 'Inactive':
        return 'secondary'
      case 'Pending':
        return 'warning'
      case 'Banned':
        return 'danger'
      default:
        return 'primary'
    }
  }
  const item = cellData.data
  return (
    <CBadge
      className="m-1"
      color={getBadge(
        item.autorizacion.length > 0 ? item.estadoElectronico : 'N/A',
      )}
    >
      {item.autorizacion.length > 0 ? item.estadoElectronico : 'N/A'}
    </CBadge>
  )
}

function convertirFechaTransaccionVenta(data: VentaListado) {
  if (data) {
    return DateUtils.strDateToDate(data.fecha, formatoFechasApi)
  }
}

function convertirFechaTransaccionGuiaRemision(data: GuiaInfo) {
  if (data) {
    return DateUtils.strDateToDate(data.fechaEmision, formatoFechasApi)
  }
}

async function anularDocumentoVenta(info: VentaListado) {
  await VentasService.anularDocumento(info)
}

function calculateCustomSummary(options) {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0
  } else if (options.summaryProcess === 'calculate') {
    options.totalValue =
      options.totalValue +
      (options.value.estado === 'ANULADO' ? 0 : options.value[options.name])
  }
}

function convertirFechaTransaccionGuiaRemisionSV(data: GuiaInfo) {
  if (data) {
    return DateUtils.strDateToDate(data.fechaEmision, formatoFechasApi)
  }
}
function reemplazarValores(cadena, valores) {
  for (const valor in valores) {
    cadena = cadena.replace(valor, valores[valor])
  }
  return cadena
}

async function parseReportDataFCFSV(data: TControlReporte) {
  console.log('parseReportDataFCFSV')

  const {
    dataInvoice,
    resAutorization,
    autorizacionDocumentoVenta,
    ivaPorcentajeMain,
    configReport,
    cliente,
  } = data
  console.log('data', data)

  const documentoData: TS_InvoiceConsumidorFinal =
    dataInvoice as TS_InvoiceConsumidorFinal
  console.log('documentoData', documentoData)

  const documento: TS_ConsumidorFinal =
    documentoData?.consumidorFinal as TS_ConsumidorFinal
  console.log('documento', documento)

  const clienteReceptor = documento.dteJson.receptor
  console.log('clienteReceptor', clienteReceptor)

  const { empresa, local, template } = configReport
  const observaciones = documento?.dteJson?.extension?.observaciones ?? ''
  console.log('observaciones', observaciones)

  const autorizationDTE = resAutorization as TAutorizacionHaciendaSV
  const fileName = autorizationDTE?.codigoGeneracion

  const urlFile = empresa?.configuracion?.urlFiles ?? ''
  let dateDoc = null

  if (
    documento?.dteJson?.identificacion?.fecEmi &&
    JsonDteElectronicHelper.isValidDateString(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  ) {
    dateDoc = JsonDteElectronicHelper.generaFechaQrEnlace(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  }

  const urlQR = generaEnlaceQr(urlFile, autorizationDTE, dateDoc)
  console.log('urlQR---->', urlQR)

  const operationCondition = isOperationCondition(
    documento?.dteJson?.resumen?.condicionOperacion,
  )

  let i = 1
  const detalleItems = documento?.dteJson?.cuerpoDocumento.map((det) => {
    const detalle: any = {
      Code: det.codigo,
      Number: i,
      Cant: det.cantidad,
      Unite: det.uniMedida,
      Name: det.descripcion,
      UnitPrice: det.precioUni,
      OtherMounts: det.noGravado,
      DiscountIte: det.montoDescu,
      SaleUnSujet: det.ventaNoSuj,
      SaleExcent: det.ventaExenta,
      SaleSaved: det.ventaGravada,
    }
    i++
    return detalle
  })

  let tipoEstablecimiento: TipoEstablecimientoOption | null = null
  let desTipoEstablecimiento: string = ''
  const tiposEstApi = await tipoEstablecimientoService.getTiposEstablecimientos(
    'Elija una opción',
    empresa?.codigoPais,
  )
  if (tiposEstApi.auto && tiposEstApi.auto.length > 0) {
    const selectedIndex = tiposEstApi.auto.findIndex(
      (select: TipoEstablecimientoOption) => {
        return select.codigo === local.tipo
      },
    )
    if (selectedIndex > -1) {
      tipoEstablecimiento = tiposEstApi.auto[selectedIndex]
      desTipoEstablecimiento = tipoEstablecimiento.descripcion
    }
  }

  const direccionReceptor =
    documento?.dteJson?.receptor?.direccion?.complemento ?? ''
  const Transmitter: TS_Transmitter = {
    Code: 0,
    Nit: documento?.dteJson?.emisor?.nit ?? '',
    Nrc: documento?.dteJson?.emisor?.nrc ?? '',
    EconomicAntivity: documento?.dteJson?.emisor?.descActividad ?? '',
    Direction: documento?.dteJson?.emisor?.direccion?.complemento ?? '',
    Phone: documento?.dteJson?.emisor?.telefono ?? '',
    Email: documento?.dteJson?.emisor?.correo ?? '',
    ComertialName: empresa?.comercial ?? '',
    StablishmentType: desTipoEstablecimiento,
    BusinessName: empresa?.nombre ?? '',
  }
  console.log('parseReportDataFCFSV')
  console.log('cliente', cliente)

  const Receiver = {
    Code: 0,
    Nit: documento?.dteJson?.receptor?.numDocumento ?? '',
    Nrc: documento?.dteJson?.receptor?.nrc ?? '',
    EconomicAntivity: documento?.dteJson?.receptor?.descActividad ?? '',
    Direction: direccionReceptor,
    Phone: documento?.dteJson?.receptor?.telefono ?? '',
    Email: documento?.dteJson?.receptor?.correo ?? '',
    ComertialName: cliente?.nombreComercial ?? '',
    SocialName: cliente?.nombres ?? '',
    StablishmentType: '',
  }

  if (Receiver.Nit === config.rucConsumidorFinal) {
    Receiver.ComertialName = clienteReceptor.nombreComercial
    Receiver.Nit = clienteReceptor.nit
    Receiver.Email = clienteReceptor.correo
    Receiver.Phone = clienteReceptor.telefono
  }

  if (Receiver.Nit === '') {
    Receiver.SocialName = documento?.dteJson?.receptor?.nombre
    Receiver.ComertialName = ''
  }
  console.log('Receiver', Receiver)

  const totalSale = utilidades.formatDecimal(
    documento?.dteJson?.resumen?.subTotalVentas ?? 0,
    2,
  )

  let IvaPorcentaje = []

  IvaPorcentaje = [
    {
      Porcentaje: ivaPorcentajeMain,
      Valor: documento?.dteJson?.resumen?.totalIva,
    },
  ]
  // -${documento?.dteJson?.identificacion?.horEmi}
  const dateEMitionsDte = `${documento?.dteJson?.identificacion?.fecEmi}`
  const dteProcessedDate = resAutorization?.fechaProcesamiento

  const abrev = getAbreviaturaFromTipo(
    documento?.dteJson?.identificacion.tipoDte as TiposComprobantesSLV,
  )

  const reporteFcFsv = {
    local: local,
    template: template,
    reportData: [
      {
        code: 0,
        CodeGenerated: autorizationDTE?.codigoGeneracion ?? '',
        UrlDocument: urlQR,
        ControlNumber:
          documento?.dteJson?.identificacion?.numeroControl?.toUpperCase() ??
          '',
        ReceiptStamp: autorizationDTE?.selloRecibido ?? '',
        InvoiceModel: getTipoModelo(
          documento?.dteJson?.identificacion?.tipoModelo,
        ),
        TransactionType: getTipoOperacion(
          documento?.dteJson?.identificacion?.tipoOperacion,
        ),
        DteAbreviature: abrev,
        Receiver: Receiver,
        Transmitter: Transmitter,
        Establishment: autorizacionDocumentoVenta?.establecimiento ?? '',
        EmissionPoint: autorizacionDocumentoVenta?.ptoemision ?? '',
        InvoiceDate: dateEMitionsDte,
        DteProcessedDate: dteProcessedDate,
        Details: detalleItems,
        TotalSales: {
          SaleUnSujet: documento?.dteJson?.resumen?.totalNoSuj ?? 0,
          SaleExcent: documento?.dteJson?.resumen?.totalExenta ?? 0,
          SaleSaved: documento?.dteJson?.resumen?.totalPagar ?? 0,
        },
        Totals: {
          TotSales: totalSale ?? 0,
          MounGlobalDescRebajaUnSuject: 0,
          MounGlobalDescRebajaSuject:
            documento?.dteJson?.resumen?.descuExenta ?? 0,
          MounGlobalDescRebajaSaved:
            documento?.dteJson?.resumen?.totalDescu ?? 0,
          TributeName: '',
          Subtotal: documento?.dteJson?.resumen?.subTotal ?? 0,
          IvaDetained: documento?.dteJson?.resumen?.ivaRete1 ?? 0,
          RetenctionRent: documento?.dteJson?.resumen?.reteRenta ?? 0,
          MountTotalOperation:
            documento?.dteJson?.resumen?.montoTotalOperacion ?? 0,
          OtherMountTotalOperationUnEffects: 0,
          TotalPay: documento?.dteJson?.resumen?.totalPagar ?? 0,
          IvaPorcentaje: IvaPorcentaje,
        },
        ValueInLetters: documento?.dteJson?.resumen?.totalLetras ?? '',
        Observations: observaciones,
        ConditionOperation: operationCondition ?? '',
        ResponsibleTransmitter: documento?.dteJson?.emisor?.nombre ?? '',
        ResNumberTransmitter: documento?.dteJson?.emisor?.nit ?? '',
        ResponsibleReceiver: documento?.dteJson?.receptor?.nombre ?? '',
        ResNumberReceiver: documento?.dteJson?.receptor?.numDocumento ?? '',
        Premise: onParseLocal(local) ?? null,
      },
    ],
    fileName: fileName,
  }
  console.log('reporteFcFsv', reporteFcFsv)
  return reporteFcFsv
}

async function parseReportDataSVCCF(data: any) {
  const {
    dataInvoice,
    resAutorization,
    autorizacionDocumentoVenta,
    ControlNumber,
    empresa,
    observaciones,
    local,
    cliente,
    template,
    fileName,
    ivaPorcentajes,
  } = data
  console.log('parseReportDataSVCCF cliente', cliente)

  const documento = dataInvoice?.comprobanteCreditoFiscal

  const urlFile = empresa?.configuracion?.urlFiles ?? ''
  let dateDoc = null

  if (
    documento?.dteJson?.identificacion?.fecEmi &&
    JsonDteElectronicHelper.isValidDateString(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  ) {
    dateDoc = JsonDteElectronicHelper.generaFechaQrEnlace(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  }
  console.log('dateDoc', dateDoc)
  const urlQR = generaEnlaceQr(urlFile, resAutorization, dateDoc)
  console.log('urlQR---->', urlQR)

  const operationCondition = isOperationCondition(
    documento?.dteJson?.resumen?.condicionOperacion,
  )

  let i = 1

  const tributos = documento.dteJson?.resumen?.tributos
  const IvaDetained = tributos?.length > 0 ? tributos[0].valor : 0
  consoleService.log({ IvaDetained, tributos, dataInvoice })
  const detalleItems = documento?.dteJson?.cuerpoDocumento.map((det) => {
    const detalle: any = {
      Code: det.codigo,
      Number: i,
      Cant: det.cantidad,
      Unite: det.uniMedida,
      Name: det.descripcion,
      UnitPrice: det.precioUni,
      OtherMounts: det.noGravado,
      DiscountIte: det.montoDescu,
      SaleUnSujet: det.ventaNoSuj,
      SaleExcent: det.ventaExenta,
      SaleSaved: det.ventaGravada,
    }
    i++
    return detalle
  })

  let tipoEstablecimiento: TipoEstablecimientoOption | null = null
  let desTipoEstablecimiento: string = ''
  const tiposEstApi = await tipoEstablecimientoService.getTiposEstablecimientos(
    'Elija una opción',
    empresa.codigoPais,
  )
  if (tiposEstApi.auto && tiposEstApi.auto.length > 0) {
    const selectedIndex = tiposEstApi.auto.findIndex(
      (select: TipoEstablecimientoOption) => {
        return select.codigo === local.tipo
      },
    )
    if (selectedIndex > -1) {
      tipoEstablecimiento = tiposEstApi.auto[selectedIndex]
      desTipoEstablecimiento = tipoEstablecimiento.descripcion
    }
  }

  const descuGravada = documento?.dteJson?.resumen?.descuGravada ?? 0
  const descuExenta = documento?.dteJson?.resumen?.descuExenta ?? 0
  const totalGravada = documento?.dteJson?.resumen?.totalGravada ?? 0
  const totalExenta = documento?.dteJson?.resumen?.totalExenta ?? 0
  const Subtotal = totalGravada + totalExenta - descuGravada - descuExenta

  const direccionReceptor =
    documento?.dteJson?.receptor?.direccion?.complemento ?? ''
  const Transmitter: TS_Transmitter = {
    Code: 0,
    Nit: documento?.dteJson?.emisor?.nit ?? '',
    Nrc: documento?.dteJson?.emisor?.nrc ?? '',
    EconomicAntivity: documento?.dteJson?.emisor?.descActividad ?? '',
    Direction: documento?.dteJson?.emisor?.direccion?.complemento ?? '',
    Phone: documento?.dteJson?.emisor?.telefono ?? '',
    Email: documento?.dteJson?.emisor?.correo ?? '',
    ComertialName: empresa?.comercial ?? '',
    StablishmentType: desTipoEstablecimiento,
    BusinessName: empresa?.nombre ?? '',
  }

  const Receiver = {
    Code: 0,
    Nit: documento?.dteJson?.receptor?.nit ?? '',
    Nrc: documento?.dteJson?.receptor?.nrc ?? '',
    EconomicAntivity: documento?.dteJson?.receptor?.descActividad ?? '',
    Direction: direccionReceptor,
    Phone: documento?.dteJson?.receptor?.telefono ?? '',
    Email: documento?.dteJson?.receptor?.correo ?? '',
    ComertialName: cliente?.nombreComercial ?? '',
    SocialName: cliente?.nombres ?? '',
    StablishmentType: documento?.dteJson?.receptor?.tipoEstablecimiento ?? '',
  }

  let IvaPorcentaje = []

  IvaPorcentaje = ivaPorcentajes.map((c) => {
    return {
      Porcentaje: c?.porcentaje,
      Valor: c?.total,
    }
  })
  // -${documento?.dteJson?.identificacion?.horEmi}
  const dateEMitionsDte = `${documento?.dteJson?.identificacion?.fecEmi}`

  let dteProcessedDate = resAutorization?.fhProcesamiento
  if (resAutorization?.fechaProcesamiento) {
    dteProcessedDate = resAutorization?.fechaProcesamiento
  }
  return {
    local: local,
    template: template,
    reportData: [
      {
        code: 0,
        CodeGenerated: resAutorization?.codigoGeneracion ?? '',
        UrlDocument: urlQR,
        ControlNumber: ControlNumber?.toUpperCase() ?? '',
        ReceiptStamp: resAutorization?.selloRecibido ?? '',
        InvoiceModel: getTipoModelo(
          documento?.dteJson?.identificacion?.tipoModelo,
        ),
        TransactionType: getTipoOperacion(
          documento?.dteJson?.identificacion?.tipoOperacion,
        ),
        DteAbreviature: 'CCFE',
        Receiver: Receiver,
        Transmitter: Transmitter,
        Establishment: autorizacionDocumentoVenta?.establecimiento ?? '',
        EmissionPoint: autorizacionDocumentoVenta?.ptoemision ?? '',
        InvoiceDate: dateEMitionsDte,
        DteProcessedDate: dteProcessedDate,
        Details: detalleItems,
        TotalSales: {
          SaleUnSujet: documento?.dteJson?.resumen?.totalNoSuj ?? 0,
          SaleExcent: documento?.dteJson?.resumen?.totalExenta ?? 0,
          SaleSaved: documento?.dteJson?.resumen?.totalPagar ?? 0,
        },
        Totals: {
          TotSales:
            documento?.dteJson?.resumen?.totalGravada +
            documento?.dteJson?.resumen?.totalExenta,
          MounGlobalDescRebajaUnSuject:
            documento?.dteJson?.resumen?.descuNoSuj ?? 0,
          MounGlobalDescRebajaSuject: descuExenta,
          MounGlobalDescRebajaSaved: descuGravada,
          TributeName: '',
          Subtotal: Subtotal ?? 0,
          IvaDetained: IvaDetained ?? 0,
          RetenctionRent: documento?.dteJson?.resumen?.reteRenta ?? 0,
          MountTotalOperation:
            documento?.dteJson?.resumen?.montoTotalOperacion ?? 0,
          OtherMountTotalOperationUnEffects: 0,
          TotalPay: documento?.dteJson?.resumen?.totalPagar ?? 0,
          RetenctionIva: documento?.dteJson?.resumen?.ivaRete1 ?? 0,
          IvaPorcentaje: IvaPorcentaje,
        },
        ValueInLetters: documento?.dteJson?.resumen?.totalLetras ?? '',
        Observations: observaciones ?? '',
        ConditionOperation: operationCondition ?? '',
        ResponsibleTransmitter: documento?.dteJson?.emisor?.nombre ?? '',
        ResNumberTransmitter: documento?.dteJson?.emisor?.nit ?? '',
        ResponsibleReceiver: documento?.dteJson?.receptor?.nombre ?? '',
        ResNumberReceiver: documento?.dteJson?.receptor?.nit ?? '',
        Premise: onParseLocal(local) ?? null,
      },
    ],
    fileName: fileName ? fileName : resAutorization?.selloRecibido ?? '',
  }
}

async function parseReportDataSVExpo(data: any) {
  const {
    dataInvoice,
    resAutorization,
    autorizacionDocumentoVenta,
    fileName,
    controlNumber,
    empresa,
    local,
    template,
    observaciones,
    cliente,
  } = data
  const documento = dataInvoice?.facturaExportacion

  const urlFile = empresa?.configuracion?.urlFiles ?? ''
  let dateDoc = null
  if (
    documento?.dteJson?.identificacion?.fecEmi &&
    JsonDteElectronicHelper.isValidDateString(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  ) {
    dateDoc = JsonDteElectronicHelper.generaFechaQrEnlace(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  }
  console.log('dateDoc', dateDoc)

  const urlQR = generaEnlaceQr(urlFile, resAutorization, dateDoc)
  console.log('urlQR---->', urlQR)

  const operationCondition = isOperationCondition(
    documento?.dteJson?.resumen?.condicionOperacion,
  )

  let i = 1
  const detalleItems = documento?.dteJson?.cuerpoDocumento.map((det) => {
    const detalle: any = {
      Code: det.codigo,
      Number: i,
      Cant: det.cantidad,
      Unite: det.uniMedida,
      Name: det.descripcion,
      UnitPrice: det.precioUni,
      OtherMounts: det.noGravado,
      DiscountIte: det.montoDescu,
      SaleUnSujet: det.ventaNoSuj,
      SaleExcent: det.ventaExenta,
      SaleSaved: det.ventaGravada,
    }
    i++
    return detalle
  })

  let tipoEstablecimiento: TipoEstablecimientoOption | null = null
  let desTipoEstablecimiento: string = ''
  const tiposEstApi = await tipoEstablecimientoService.getTiposEstablecimientos(
    'Elija una opción',
    empresa.codigoPais,
  )
  if (tiposEstApi.auto && tiposEstApi.auto.length > 0) {
    const selectedIndex = tiposEstApi.auto.findIndex(
      (select: TipoEstablecimientoOption) => {
        return select.codigo === local.tipo
      },
    )
    if (selectedIndex > -1) {
      tipoEstablecimiento = tiposEstApi.auto[selectedIndex]
      desTipoEstablecimiento = tipoEstablecimiento.descripcion
    }
  }

  let direccionReceptor =
    documento?.dteJson?.receptor?.direccion?.complemento ?? ''
  const Transmitter: TS_Transmitter = {
    Code: 0,
    Nit: documento?.dteJson?.emisor?.nit ?? '',
    Nrc: documento?.dteJson?.emisor?.nrc ?? '',
    EconomicAntivity: documento?.dteJson?.emisor?.descActividad ?? '',
    Direction: documento?.dteJson?.emisor?.direccion?.complemento ?? '',
    Phone: documento?.dteJson?.emisor?.telefono ?? '',
    Email: documento?.dteJson?.emisor?.correo ?? '',
    ComertialName: empresa?.comercial ?? '',
    StablishmentType: desTipoEstablecimiento,
    BusinessName: empresa?.nombre ?? '',
  }

  Transmitter.Regime = documento?.dteJson?.emisor?.regimen ?? ''
  Transmitter.Recint = documento?.dteJson?.emisor?.recintoFiscal ?? ''
  Transmitter.Country = ''
  direccionReceptor = documento?.dteJson?.receptor?.complemento ?? ''

  const dateEMitionsDte = `${documento?.dteJson?.identificacion?.fecEmi}-${documento?.dteJson?.identificacion?.horEmi}`
  let dteProcessedDate = resAutorization?.fhProcesamiento
  if (resAutorization?.fechaProcesamiento) {
    dteProcessedDate = resAutorization?.fechaProcesamiento
  }
  let receptor = null
  receptor = {
    Code: 0,
    Nit: documento?.dteJson?.receptor?.numDocumento ?? '',
    Nrc: documento?.dteJson?.receptor?.nrc ?? '',
    EconomicAntivity: documento?.dteJson?.receptor?.descActividad ?? '',
    Direction: direccionReceptor,
    Phone: documento?.dteJson?.receptor?.telefono ?? '',
    Email: documento?.dteJson?.receptor?.correo ?? '',
    ComertialName: documento?.dteJson?.receptor?.nombre ?? '',
    StablishmentType: documento?.dteJson?.receptor?.tipoEstablecimiento ?? '',
    Country: documento?.dteJson?.receptor?.nombrePais ?? '',
  }
  if (cliente && cliente?.nrc) {
    receptor.Nrc = cliente?.nrc ?? null
  }
  return {
    local: local,
    template,
    reportData: [
      {
        code: 0,
        CodeGenerated: resAutorization?.codigoGeneracion?.toUpperCase() ?? '',
        UrlDocument: urlQR,
        ControlNumber: controlNumber ?? '',
        ReceiptStamp: resAutorization?.selloRecibido ?? '',
        InvoiceModel: getTipoModelo(
          documento?.dteJson?.identificacion?.tipoModelo,
        ),
        TransactionType: getTipoOperacion(
          documento?.dteJson?.identificacion?.tipoOperacion,
        ),
        DteAbreviature: 'FEXE',
        Receiver: receptor,
        Transmitter: Transmitter,
        Establishment: autorizacionDocumentoVenta?.establecimiento ?? '',
        EmissionPoint: autorizacionDocumentoVenta?.ptoemision ?? '',
        InvoiceDate: dateEMitionsDte,
        DteProcessedDate: dteProcessedDate,
        Details: detalleItems,
        TotalSales: {
          SaleUnSujet: documento?.dteJson?.resumen?.totalNoSuj ?? 0,
          SaleExcent: documento?.dteJson?.resumen?.totalExenta ?? 0,
          SaleSaved: documento?.dteJson?.resumen?.totalPagar ?? 0,
        },
        Totals: {
          TotSales: documento?.dteJson?.resumen?.totalGravada ?? 0,
          MounGlobalDescRebajaUnSuject:
            documento?.dteJson?.resumen?.descuNoSuj ?? 0,
          MounGlobalDescRebajaSuject:
            documento?.dteJson?.resumen?.descuExenta ?? 0,
          MounGlobalDescRebajaSaved:
            documento?.dteJson?.resumen?.totalDescu ?? 0,
          TributeName: '',
          Subtotal: documento?.dteJson?.resumen?.subTotal ?? 0,
          IvaDetained: documento?.dteJson?.resumen?.totalIva ?? 0,
          RetenctionRent: documento?.dteJson?.resumen?.reteRenta ?? 0,
          MountTotalOperation:
            documento?.dteJson?.resumen?.montoTotalOperacion ?? 0,
          OtherMountTotalOperationUnEffects: 0,
          TotalPay: documento?.dteJson?.resumen?.totalPagar ?? 0,
          Flete: documento?.dteJson?.resumen?.flete ?? 0,
          Seguro: documento?.dteJson?.resumen?.seguro ?? 0,
        },
        ValueInLetters: documento?.dteJson?.resumen?.totalLetras ?? '',
        Observations: observaciones ?? '',
        ConditionOperation: operationCondition ?? '',
        ResponsibleTransmitter: documento?.dteJson?.emisor?.nombre ?? '',
        ResNumberTransmitter: documento?.dteJson?.emisor?.nit ?? '',
        ResponsibleReceiver: documento?.dteJson?.receptor?.nombre ?? '',
        ResNumberReceiver: documento?.dteJson?.receptor?.numDocumento ?? '',
        Premise: onParseLocal(local) ?? null,
      },
    ],
    fileName: fileName ? fileName : resAutorization?.selloRecibido ?? '',
  }
}

async function parseReportDataSVSujExcluido(data: any) {
  const {
    dataInvoice,
    resAutorization,
    autorizacionDocumentoVenta,
    controlNumber,
    empresa,
    observaciones,
    local,
    template,
    fileName,
  } = data
  const documento = dataInvoice.sujetoExcluido

  const urlFile = empresa?.configuracion?.urlFiles ?? ''
  let dateDoc = null
  if (
    documento?.dteJson?.identificacion?.fecEmi &&
    JsonDteElectronicHelper.isValidDateString(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  ) {
    dateDoc = JsonDteElectronicHelper.generaFechaQrEnlace(
      documento?.dteJson?.identificacion?.fecEmi,
    )
  }
  console.log('dateDoc', dateDoc)

  const urlQR = generaEnlaceQr(urlFile, resAutorization, dateDoc)
  console.log('urlQR---->', urlQR)

  const operationCondition = isOperationCondition(
    documento?.dteJson?.resumen?.condicionOperacion,
  )

  const templatePDF = template
  let i = 1
  const detalleItems = documento?.dteJson?.cuerpoDocumento.map((det) => {
    const detalle: any = {
      Code: det.codigo,
      Number: i,
      Cant: det.cantidad,
      Unite: det.uniMedida,
      Name: det.descripcion,
      UnitPrice: parseFloat(det.precioUni.toFixed(2)),
      OtherMounts: det.noGravado,
      DiscountIte: det.montoDescu,
      TotalSales: det.compra,
    }
    i++
    return detalle
  })

  let tipoEstablecimiento: TipoEstablecimientoOption | null = null
  let desTipoEstablecimiento: string = ''
  const tiposEstApi = await tipoEstablecimientoService.getTiposEstablecimientos(
    'Elija una opción',
    empresa.codigoPais,
  )
  if (tiposEstApi.auto && tiposEstApi.auto.length > 0) {
    const selectedIndex = tiposEstApi.auto.findIndex(
      (select: TipoEstablecimientoOption) => {
        return select.codigo === local.tipo
      },
    )
    if (selectedIndex > -1) {
      tipoEstablecimiento = tiposEstApi.auto[selectedIndex]
      desTipoEstablecimiento = tipoEstablecimiento.descripcion
    }
  }

  const direccionReceptor =
    documento?.dteJson?.sujetoExcluido?.direccion?.complemento ?? ''
  const Transmitter: TS_Transmitter = {
    Code: 0,
    Nit: documento?.dteJson?.emisor?.nit ?? '',
    Nrc: documento?.dteJson?.emisor?.nrc ?? '',
    EconomicAntivity: documento?.dteJson?.emisor?.descActividad ?? '',
    Direction: documento?.dteJson?.emisor?.direccion?.complemento ?? '',
    Phone: documento?.dteJson?.emisor?.telefono ?? '',
    Email: documento?.dteJson?.emisor?.correo ?? '',
    ComertialName: empresa?.comercial ?? '',
    StablishmentType: desTipoEstablecimiento,
    BusinessName: empresa?.nombre ?? '',
  }
  const dateEMitionsDte = `${documento?.dteJson?.identificacion?.fecEmi}-${documento?.dteJson?.identificacion?.horEmi}`
  let dteProcessedDate = resAutorization?.fhProcesamiento
  if (resAutorization?.fechaProcesamiento) {
    dteProcessedDate = resAutorization?.fechaProcesamiento
  }
  return {
    local: local,
    template: templatePDF,
    reportData: [
      {
        code: 0,
        CodeGenerated: resAutorization?.codigoGeneracion?.toUpperCase() ?? '',
        UrlDocument: urlQR,
        ControlNumber: controlNumber?.toUpperCase() ?? '',
        ReceiptStamp: resAutorization?.selloRecibido ?? '',
        InvoiceModel: getTipoModelo(
          documento?.dteJson?.identificacion?.tipoModelo,
        ),
        TransactionType: getTipoOperacion(
          documento?.dteJson?.identificacion?.tipoOperacion,
        ),
        DteAbreviature: 'FSEE',
        Receiver: {
          Code: 0,
          TypoIdentification: obtenerNombreTipoComprobante(
            documento?.dteJson?.sujetoExcluido?.tipoDocumento ?? '',
          ),
          Nit: documento?.dteJson?.sujetoExcluido?.numDocumento ?? '',
          Nrc: documento?.dteJson?.sujetoExcluido?.nrc ?? '',
          EconomicAntivity:
            documento?.dteJson?.sujetoExcluido?.descActividad ?? '',
          Direction: direccionReceptor,
          Phone: documento?.dteJson?.sujetoExcluido?.telefono ?? '',
          Email: documento?.dteJson?.sujetoExcluido?.correo ?? '',
          ComertialName: documento?.dteJson?.sujetoExcluido?.nombre ?? '',
          StablishmentType:
            documento?.dteJson?.sujetoExcluido?.tipoEstablecimiento ?? '',
        },
        Transmitter: Transmitter,
        Establishment: autorizacionDocumentoVenta?.establecimiento ?? '',
        EmissionPoint: autorizacionDocumentoVenta?.ptoemision ?? '',
        InvoiceDate: dateEMitionsDte ?? '',
        DteProcessedDate: dteProcessedDate,
        Details: detalleItems,
        TotalSales: {
          SaleUnSujet: documento?.dteJson?.resumen?.totalNoSuj ?? 0,
          SaleExcent: documento?.dteJson?.resumen?.totalExenta ?? 0,
          SaleSaved: documento?.dteJson?.resumen?.totalPagar ?? 0,
        },
        Totals: {
          TotSales: documento?.dteJson?.resumen?.subTotal ?? 0,
          MounGlobalDescRebajaUnSuject:
            documento?.dteJson?.resumen?.descuNoSuj ?? 0,
          MounGlobalDescRebajaSuject:
            documento?.dteJson?.resumen?.descuExenta ?? 0,
          MounGlobalDescRebajaSaved:
            documento?.dteJson?.resumen?.descuGravada ?? 0,
          TributeName: '',
          Subtotal: documento?.dteJson?.resumen?.subTotal ?? 0,
          IvaDetained: documento?.dteJson?.resumen?.ivaRete1 ?? 0,
          RetenctionRent: documento?.dteJson?.resumen?.reteRenta ?? 0,
          MountTotalOperation:
            documento?.dteJson?.resumen?.montoTotalOperacion ?? 0,
          OtherMountTotalOperationUnEffects: 0,
          TotalPay: documento?.dteJson?.resumen?.totalPagar ?? 0,
        },
        ValueInLetters: documento?.dteJson?.resumen?.totalLetras ?? '',
        Observations: observaciones ?? '',
        ConditionOperation: operationCondition ?? '',
        ResponsibleTransmitter: documento?.dteJson?.emisor?.nombre ?? '',
        ResNumberTransmitter: documento?.dteJson?.emisor?.nit ?? '',
        ResponsibleReceiver: documento?.dteJson?.sujetoExcluido?.nombre ?? '',
        ResNumberReceiver:
          documento?.dteJson?.sujetoExcluido?.numDocumento ?? '',
        Premise: onParseLocal(local) ?? null,
      },
    ],
    fileName: fileName ? fileName : resAutorization?.selloRecibido ?? '',
  }
}

async function parserReportDataSVNotaCredito(data: any) {
  console.log('parserReportDataSVNotaCredito 2', data)

  const {
    dataInvoice,
    resAutorization,
    autorizacionDocumentoVenta,
    documentoRelacionado,
    ControlNumber,
    empresa,
    observaciones,
    local,
    template,
    fileName,
  } = data

  const urlFile = empresa?.configuracion?.urlFiles ?? ''
  let dateDoc = null
  const descDate = resAutorization?.fhProcesamiento?.split(' ') ?? []
  if (descDate.length > 0) {
    dateDoc = DateUtils.apiDateToPickersDate(descDate[0])
  }

  console.log(
    'dataInvoice?.notaCredito?.dteJson?.resumen 2',
    dataInvoice?.notaCredito?.dteJson?.resumen,
  )

  const urlQR = generaEnlaceQr(urlFile, resAutorization, dateDoc)
  console.log('urlQR---->', urlQR)

  let totalIva = 0
  if (dataInvoice?.notaCredito?.dteJson?.resumen?.tributos.length > 0) {
    if (
      Number(
        dataInvoice?.notaCredito?.dteJson?.resumen?.tributos[0]?.codigo,
      ) === 20
    ) {
      totalIva =
        dataInvoice?.notaCredito?.dteJson?.resumen?.tributos[0]?.valor ?? 0.0
    }
  }
  console.log('totalIva 2', totalIva)

  const tributos = dataInvoice?.notaCredito?.dteJson?.resumen?.tributos
  const IvaDetained = tributos?.length > 0 ? tributos[0].valor : 0
  let docName = fileName ? fileName : resAutorization?.selloRecibido ?? ''
  docName = docName.toUpperCase()
  console.log('docName 2', docName)

  const operationCondition = isOperationCondition(
    dataInvoice?.notaCredito?.dteJson?.resumen?.condicionOperacion,
  )
  let dteProcessedDate = resAutorization?.fhProcesamiento
  if (resAutorization?.fechaProcesamiento) {
    dteProcessedDate = resAutorization?.fechaProcesamiento
  }
  return {
    local: local,
    template: template,
    reportData: [
      {
        code: 0,
        CodeGenerated: resAutorization?.codigoGeneracion?.toUpperCase() ?? '',
        UrlDocument: urlQR,
        ControlNumber: ControlNumber,
        ReceiptStamp: resAutorization?.selloRecibido ?? '',
        InvoiceModel: getTipoModelo(
          dataInvoice?.notaCredito?.dteJson?.identificacion?.tipoModelo,
        ),
        TransactionType: 'NOTA CREDITO',
        DocumentRelated: {
          CodeDocumentType: documentoRelacionado?.tipoDocumentoCod ?? '',
          DescriptionDocumentType: getTipoDocumento(
            documentoRelacionado?.tipoDocumentoCod,
          ),
          DocumentNumber: documentoRelacionado?.numeroDocumento ?? '',
          DateEmition: documentoRelacionado?.fechaEmision ?? '',
        },
        Transmitter: {
          Code: 0,
          Nit: dataInvoice?.notaCredito?.dteJson?.emisor?.nit ?? '',
          Nrc: dataInvoice?.notaCredito?.dteJson?.emisor?.nrc ?? '',
          EconomicAntivity:
            dataInvoice?.notaCredito?.dteJson?.emisor?.descActividad ?? '',
          Direction:
            dataInvoice?.notaCredito?.dteJson?.emisor?.direccion?.complemento ??
            '',
          Phone: dataInvoice?.notaCredito?.dteJson?.emisor?.telefono ?? '',
          Email: dataInvoice?.notaCredito?.dteJson?.emisor?.correo ?? '',
          ComertialName: empresa?.comercial ?? '',
          StablishmentType:
            dataInvoice?.notaCredito?.dteJson?.emisor?.tipoEstablecimiento ??
            '',
          BusinessName: empresa?.nombre ?? '',
        },
        Receiver: {
          Code: 0,
          Nit: dataInvoice?.notaCredito?.dteJson?.receptor?.nit ?? '',
          Nrc: dataInvoice?.notaCredito?.dteJson?.receptor?.nrc ?? '',
          EconomicAntivity:
            dataInvoice?.notaCredito?.dteJson?.receptor?.descActividad ?? '',
          Direction:
            dataInvoice?.notaCredito?.dteJson?.receptor?.direccion
              ?.complemento ?? '',
          Phone: dataInvoice?.notaCredito?.dteJson?.receptor?.telefono ?? '',

          Email: dataInvoice?.notaCredito?.dteJson?.receptor?.correo ?? '',
          ComertialName:
            dataInvoice?.notaCredito?.dteJson?.receptor?.nombreComercial ?? '',
          StablishmentType: '',
        },
        Establishment: autorizacionDocumentoVenta?.establecimiento ?? '',
        EmissionPoint: autorizacionDocumentoVenta?.ptoemision ?? '',
        InvoiceDate:
          dataInvoice?.notaCredito?.dteJson?.identificacion?.fecEmi ?? '',
        DteProcessedDate: dteProcessedDate,
        Details: dataInvoice?.notaCredito?.dteJson?.cuerpoDocumento.map(
          (det, i = 1) => {
            const detalle: any = {
              Code: det.codigo,
              Number: i + 1,
              Cant: det.cantidad,
              Unite: det.uniMedida,
              Name: det.descripcion,
              UnitPrice: utilidades.formatDecimal(det.precioUni, 2),
              DiscountIte: utilidades.formatDecimal(det.montoDescu, 2),
              SaleUnSujet: utilidades.formatDecimal(det.ventaNoSuj, 2),
              SaleExcent: utilidades.formatDecimal(det.ventaExenta, 2),
              SaleSaved: utilidades.formatDecimal(det.ventaGravada, 2),
            }
            return detalle
          },
        ),
        TotalSales: {
          SaleUnSujet:
            dataInvoice?.notaCredito?.dteJson?.resumen?.totalNoSuj ?? 0,
          SaleExcent:
            dataInvoice?.notaCredito?.dteJson?.resumen?.totalExenta ?? 0,
          SaleSaved:
            dataInvoice?.notaCredito?.dteJson?.resumen?.montoTotalOperacion ??
            0,
        },
        Totals: {
          TotSales:
            dataInvoice?.notaCredito?.dteJson?.resumen?.montoTotalOperacion ??
            0,
          MounGlobalDescRebajaUnSuject:
            dataInvoice?.notaCredito?.dteJson?.resumen?.descuNoSuj ?? 0,
          MounGlobalDescRebajaSuject:
            dataInvoice?.notaCredito?.dteJson?.resumen?.descuExenta ?? 0,
          MounGlobalDescRebajaSaved:
            dataInvoice?.notaCredito?.dteJson?.resumen?.descuGravada ?? 0,
          TributeName: '',
          Subtotal: dataInvoice?.notaCredito?.dteJson?.resumen?.subTotal ?? 0,
          RetenctionRent:
            dataInvoice?.notaCredito?.dteJson?.resumen?.reteRenta ?? 0,
          RetenctionIva:
            dataInvoice?.notaCredito?.dteJson?.resumen?.ivaRete1 ?? 0,
          MountTotalOperation:
            dataInvoice?.notaCredito?.dteJson?.resumen?.montoTotalOperacion ??
            0,
          TotalIva: totalIva ?? 0,
          IvaDetained: IvaDetained,

          OtherMountTotalOperationUnEffects: 0,
          TotalPay:
            dataInvoice?.notaCredito?.dteJson?.resumen?.montoTotalOperacion ??
            0,
        },
        ValueInLetters:
          dataInvoice?.notaCredito?.dteJson?.resumen?.totalLetras ?? '',
        Observations:
          dataInvoice?.notaCredito?.dteJson?.extension?.observaciones ?? '',
        ConditionOperation: operationCondition,

        ResponsibleTransmitter:
          dataInvoice?.notaCredito?.dteJson?.emisor?.nombre ?? '',
        ResNumberTransmitter:
          dataInvoice?.notaCredito?.dteJson?.emisor?.nit ?? '',
        ResponsibleReceiver:
          dataInvoice?.notaCredito?.dteJson?.receptor?.nombre ?? '',
        ResNumberReceiver:
          dataInvoice?.notaCredito?.dteJson?.receptor?.nit ?? '',
      },
    ],
    fileName: docName,
  }
}

export function getTipoModelo(type: EModeloFacturacionSLV): string {
  let tipo = 'PREVIO'
  if (type === EModeloFacturacionSLV.ModeloFacturacionprevio) {
    tipo = 'PREVIO'
  } else {
    tipo = 'DIFERIDO'
  }
  return tipo
}

function getTipoOperacion(type: ETipoTransmisionSLV): string {
  let tipo = 'NORMAL'
  if (type === ETipoTransmisionSLV.TransmisionNormal) {
    tipo = 'NORMAL'
  } else {
    tipo = 'CONTINGENCIA'
  }
  return tipo
}

function getDireccionComplemente(direccion: string): string | null {
  let data = 'S/D'
  if (direccion !== '') {
    data = direccion ?? null
  }
  return data
}

function getContacto(contacto: string): string {
  let data = '00000000'
  if (contacto !== '') {
    if (contacto?.includes(',')) {
      const array: string[] = contacto.split(',')
      data = array[0]
    } else if (contacto === 'S/N') {
      data = '00000000'
    } else {
      data = contacto
    }
  }
  return data
}

function getEmail(email: string): string {
  let data = null
  if (email !== '') {
    if (email?.includes(';')) {
      data = email?.split(';')[0] || ''
    } else {
      data = email ?? null
    }
  }
  return data
}

function getNrc(nrcData: string): string {
  let nrc = null
  if (nrcData !== '' && nrcData !== undefined) {
    nrc = nrcData
  }
  return nrc
}

function captureValuesDteNoAuth(
  obj,
  claveAcceso: string,
  tipoComprobante: TiposComprobantesSLV,
): TS_Auth {
  const result = {
    ambiente: '',
    clasificaMsg: '',
    codigoGeneracion: '',
    codigoMsg: '',
    descripcionMsg: '',
    estado: '',
    fhProcesamiento: '',
    observaciones: [],
    selloRecibido: '',
    version: 0,
    versionApp: 0,
    claveacceso: claveAcceso,
    tipoComprobante,
  }

  function search(obj) {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (key === 'fhProcesamiento') {
          result.fhProcesamiento = obj[key]
        } else if (key === 'codigoGeneracion') {
          result.codigoGeneracion = obj[key]
        } else if (key === 'ambiente') {
          result.ambiente = obj[key]
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Recursively search nested objects
          search(obj[key])
        }
      }
    }
  }
  search(obj)
  return result
}

export function generaEnlaceQr(
  urlBase: string,
  autorizationDTE: TAutorizacionHaciendaSV,
  dateDoc: string,
): string {
  let urlQR: string = ''
  const ambiente = utilidades.zeroFill(autorizationDTE?.ambiente, 2, 'left')
  const valores = {
    '%AMBIENT%': ambiente ?? '00',
    '%CODE_GENERATION%': autorizationDTE?.codigoGeneracion.toUpperCase() ?? '',
    '%DATE_EMITION%': dateDoc,
  }
  urlQR = reemplazarValores(urlBase, valores)
  return urlQR
}

// Función para obtener la abreviatura relacionada
function getAbreviaturaFromTipo(
  tipo: TiposComprobantesSLV,
): TAbrevDTESLV | undefined {
  return tiposComprobantesToAbrev[tipo]
}

export const extensionData: TS_Extension = {
  nombEntrega: null,
  docuEntrega: null,
  nombRecibe: null,
  docuRecibe: null,
  observaciones: null,
  placaVehiculo: null,
}

function getNombreUsuario(usuario): string {
  let nombre = null
  if (usuario !== undefined) {
    if (usuario?.email !== '') {
      nombre = usuario?.email
    } else if (usuario?.nombres !== '' && usuario?.apellidos !== '') {
      nombre = usuario?.nombres + ' ' + usuario?.apellidos
    } else {
      nombre = 'usuario-code - ' + usuario?.codigo
    }
  } else {
    nombre = 'usuario-code'
  }
  return nombre
}

function onParseLocal(local): TPremiseReport {
  const localParce: TPremiseReport = {
    Code: local?.codigo,
    Name: local?.nombre,
    Descount: local?.descuento,
    Address: local?.direccion,
    Stablishmen: local?.establecimiento,
    Abbrev: local?.siglas,
    Phone: local?.telefono,
    CityName: local?.ciudadNombre,
  }

  return localParce
}
/*
 * getEstabPuntoMH
 *
 * Esta función extrae y devuelve códigos de establecimiento y punto de venta
 * a partir de un número de control. Primero, elimina el prefijo 'DTE-' (si existe)
 * y luego extrae los códigos relevantes de la cadena resultante.
 *
 * @param {string} numeroControl - El número de control desde el cual se extraen los códigos.
 *
 * @returns {Object} Un objeto que contiene:
 *  - codEstableMH: Código del establecimiento en el formato MH.
 *  - codEstable: Código del establecimiento en el formato estándar.
 *  - codPuntoVentaMH: Código del punto de venta en el formato MH.
 *  - codPuntoVenta: Código del punto de venta en el formato estándar.
 *
 * @example
 * const resultado = getEstabPuntoMH('DTE-1234567890');
 * console.log(resultado);
 * // { codEstableMH: '1234', codEstable: '1234', codPuntoVentaMH: '5678', codPuntoVenta: '5678' }
 */
function getEstabPuntoMH(numeroControl: string): {
  codEstableMH: string
  codEstable: string
  codPuntoVentaMH: string
  codPuntoVenta: string
} {
  numeroControl = replaceIfExists(numeroControl, 'DTE-', '')
  return {
    codEstableMH: numeroControl.substring(3, 7),
    codEstable: numeroControl.substring(3, 7),
    codPuntoVentaMH: numeroControl.substring(7, 11),
    codPuntoVenta: numeroControl.substring(7, 11),
  }
}
/*
 * getEstabPuntoMHContingencia
 *
 * Esta función extrae y devuelve códigos de establecimiento y punto de venta
 * a partir de un número de control. Primero, elimina el prefijo 'DTE-' (si existe)
 * y luego extrae los códigos relevantes de la cadena resultante.
 *
 * @param {string} numeroControl - El número de control desde el cual se extraen los códigos.
 *
 * @returns {Object} Un objeto que contiene:
 *  - codEstableMH: Código del establecimiento en el formato MH.
 *  - codPuntoVenta: Código del punto de venta en el formato estándar.
 *
 * @example
 * const resultado = getEstabPuntoMHContingencia('DTE-1234567890');
 * console.log(resultado);
 * // { codEstableMH: '1234', codPuntoVenta: '5678' }
 */
function getEstabPuntoMHContingencia(numeroControl: string): {
  codEstableMH: string
  codPuntoVenta: string
} {
  numeroControl = replaceIfExists(numeroControl, 'DTE-', '')
  return {
    codEstableMH: numeroControl.substring(3, 7),
    codPuntoVenta: numeroControl.substring(7, 11),
  }
}

function replaceIfExists(
  original: string,
  search: string,
  replace: string,
): string {
  if (original.includes(search)) {
    return original.replace(search, replace)
  }
  return original // Devuelve la cadena original si el substring no existe
}

function eliminarCamposCuerpoDocumento(
  data: any,
  keyObject:
    | string
    | 'comprobanteCreditoFiscal'
    | 'consumidorFinal'
    | 'cuerpoDocumentoCFinal'
    | 'cuerpoDocumentoCCF'
    | 'facturaExportacion'
    | 'notaCredito'
    | 'notaDebito'
): any {
  // Eliminar las claves dentro de `cuerpoDocumento`
  if (keyObject === 'comprobanteCreditoFiscal') {
    if (
      data?.comprobanteCreditoFiscal &&
      data?.comprobanteCreditoFiscal.dteJson &&
      data?.comprobanteCreditoFiscal.dteJson.cuerpoDocumento
    ) {
      data?.comprobanteCreditoFiscal.dteJson.cuerpoDocumento.forEach(
        (item: any) => {
          delete item.ivaItem
          delete item.codigoBarras
          delete item.codigoImpuesto
        },
      )
    }
  } else if (keyObject === 'consumidorFinal') {
    if (
      data?.consumidorFinal &&
      data?.consumidorFinal.dteJson &&
      data?.consumidorFinal.dteJson.cuerpoDocumento
    ) {
      data?.consumidorFinal.dteJson.cuerpoDocumento.forEach((item: any) => {
        delete item.codigoBarras
        delete item.codigoImpuesto
      })
    }
  } else if (keyObject === 'facturaExportacion') {
    if (
      data?.facturaExportacion &&
      data?.facturaExportacion.dteJson &&
      data?.facturaExportacion.dteJson.cuerpoDocumento
    ) {
      data?.facturaExportacion.dteJson.cuerpoDocumento.forEach((item: any) => {
        delete item.codigoBarras
        delete item.codigoImpuesto
      })
    }
  } else if (keyObject === 'cuerpoDocumentoCFinal') {
    if (data?.cuerpoDocumento) {
      data?.cuerpoDocumento.forEach((item: any) => {
        delete item.codigoBarras
        delete item.codigoImpuesto
      })
    }
  } else if (keyObject === 'cuerpoDocumentoCCF') {
    if (data?.cuerpoDocumento) {
      data?.cuerpoDocumento.forEach((item: any) => {
        delete item.ivaItem
        delete item.codigoBarras
        delete item.codigoImpuesto
      })
    }
  } else if (keyObject === 'notaCredito') {
    if (data?.notaCredito?.dteJson?.cuerpoDocumento) {
      data?.notaCredito?.dteJson?.cuerpoDocumento.forEach((item: any) => {
        delete item.codigoImpuesto
        delete item.ivaItem
        delete item.psv
        delete item.noGravado
        delete item.codigoBarras
      })
    }
  } else if (keyObject === 'notaDebito') {
    if (data?.notaDebito?.dteJson?.cuerpoDocumento) {
      data?.notaDebito?.dteJson?.cuerpoDocumento.forEach((item: any) => {
        delete item.codigoImpuesto
      })
    }
  } else if (keyObject === 'cuerpoDocumento') {
    // para las notas desde el masivo
    if (data?.cuerpoDocumento) {
      data?.cuerpoDocumento.forEach((item: any) => {
        delete item.codigoImpuesto
      })
    }
  }

  return data
}

function truncateText(text: string, maxLength: number = 500): string {
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
}
