import React, { useCallback, useEffect } from 'react'
import {AnexoInfo, DatosAnuladas, TabStateAnexoTransaccional} from '../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import ValidationGroup from 'devextreme-react/validation-group'
import {
  clearButtonClick,
  closeTab,
  setButtons,
  setCurrentExecutingAction,
} from '../../../store/tabsReduxcers'
import {
  clearDatosEdicion,
  setDatosEdicion,
  updateAnulados,
} from '../../../store/editDataReducer'
import {
  CAlert,
  CCard,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import TablaVentas from './tablasTransacciones/tablaVentas'
import { anexoTransaccionalSVService } from '../../../../../services/anexoTransaccionalSV.services'
import { RootState } from '../../../../../../../../../../store/store'
import { addToast } from '../../../../../../../../../../store/toasterReducer'
import {
  CustomButtons,
  ETiposEstablecimientosMH,
  TiposComprobantesSLV,
  ToastTypes,
} from '../../../../../../../../../../store/types'
import Dialog from '../../../../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { ButtonTypes } from '../../../../../../../../../../views/componentes/globalMenu/types'
import { StatesEdition } from '../../../../../../../../types/enums'
import { LoadingIndicator } from 'devextreme-react/bar-gauge'
import BlockUi from '../../../../../../../../../../views/componentes/librerias/block-ui/BlockUi'
import RowContainer from '../../../../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../../../../views/componentes/colContainer'
import { dteSalvador } from '../../../../../data/dte'
import TablaCCF from './tablasTransacciones/tablaCCF'
import { VentaHelperSV } from '../../../../../../../ventas/helpers/ventaHelperSV'
import { VentasService } from '../../../../../../../../services/ventas.service'
import {
  DateUtils,
  formatoFechasApi,
} from '../../../../../../../../../../helpers/dateUtils'
import { utilidades } from '../../../../../../../../../../helpers/utilidades'
import TablaSuj from './tablasTransacciones/tablaSuj'
import TablaRetenciones from './tablasTransacciones/tablaRetenciones'
import { tipoIdentificacionService } from '../../../../../../../../../componentes/tipoIdentificacion/service/tipoId.service'
import {
  TipoIdentificacion,
  TipoTransaccion,
} from '../../../../../../../../../componentes/tipoIdentificacion/store/types'
import { ExportarExcelService } from '../../../../../services/exportarExcel.service'
import { CsvWriteOptions, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
import TablaNotasCredito from './tablasTransacciones/tablaNotasCredito'
import TablaAnulados from "./tablasTransacciones/tablaAnulados";

interface ICAnexoProps extends React.PropsWithChildren {
  // info: DocumentInfo<PedidoInfo>,
  info: any
  tabId: string
  tab: TabStateAnexoTransaccional<AnexoInfo>
}

export type TabConfigAnexo = {
  visible: boolean
  title: string
  code: TiposComprobantesSLV | string
}

export type ConfigDocCSV = {
  title: string
  format: string
  component: any
  filename: string
}

const Anexo: React.FunctionComponent<ICAnexoProps> = (props) => {
  const { tabId, tab } = props
  const validationGroupRef = React.useRef<any>()
  const dialogRef = React.useRef<any>(null)

  const dispatch = useDispatch()
  const usuario = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const localSession = useSelector((state: RootState) => {
    return state.global.session?.local
  })
  const loader = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].loader
  })
  const loading = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].loading
  })
  const datosEdicion = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId]
  })
  const ventas = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].consumidorFinal
  })
  const creditoFiscal = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].creditoFiscal
  })
  const exportacion = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].exportacion
  })
  const sujetoExcluido = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].sujetoExcluido
  })
  const notasCredito = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].notasCredito
  })
  const notasDebito = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].notasDebito
  })
  const notasRemision = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].notasRemision
  })

  const liquidaciones = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].liquidaciones
  })
  const liquidacionesContables = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId]
      .liquidacionesContables
  })
  const donaciones = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].donaciones
  })
  const retenciones = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].retenciones
  })
  const anulados = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.editData[tabId].anulados
  })

  const [activeKey, setActiveKey] = React.useState<TiposComprobantesSLV | null>(
    null,
  )
  const [ventasCCF, setVentasCCF] = React.useState<Array<any> | []>([])
  const [ventasAnexos, setVentasAnexos] = React.useState<Array<any> | []>([])
  const [ventasNotasCredito, setVentasNotasCredito] = React.useState<
    Array<any> | []
  >([])
  const [ventasSujetoExcluido, setVentasSujetoExcluido] = React.useState<
    Array<any> | []
  >([])
  const [ventasNotasDebito, setVentasNotasDebito] = React.useState<
    Array<any> | []
  >([])
  const [ventasExportacion, setVentasExportacion] = React.useState<
    Array<any> | []
  >([])

  const [ventasRetenciones, setVentasRetenciones] = React.useState<
    Array<any> | []
  >([])

  const agruparPorFechaNegociacion = useCallback((datos) => {
    // Crear un mapa para agrupar por fechaNegociacion
    const agrupados = {}

    datos.forEach((dato) => {
      if (dato?.fechaNegociacion) {
        if (!agrupados[dato.fechaNegociacion]) {
          agrupados[dato.fechaNegociacion] = []
        }
        agrupados[dato.fechaNegociacion].push(dato)
      }
    })

    // Crear el array resultante con los datos agrupados
    const resultado = Object.keys(agrupados).map((fecha) => {
      const grupo = agrupados[fecha]
      console.log('fecha', fecha)
      console.log('grupo', grupo)

      // Ordenar el grupo por 'numDocumentoDel' (asumiendo que es una cadena que puede ser ordenada lexicográficamente)
      grupo.sort((a, b) => a.numDocumentoDel.localeCompare(b.numDocumentoDel))

      const subtotal0 = grupo.reduce((t, item) => {
        t += item.subtotal0
        return t
      }, 0)
      const ventasProporcionalidad = grupo.reduce((t, item) => {
        t += item.ventasProporcionalidad
        return t
      }, 0)
      const ventasNoSujetas = grupo.reduce((t, item) => {
        t += item.ventasNoSujetas
        return t
      }, 0)
      const subtotalIva = grupo.reduce((t, item) => {
        t += item.subtotalIva
        return t
      }, 0)
      const expoDentroCentroAmerica = grupo.reduce((t, item) => {
        t += item.expoDentroCentroAmerica
        return t
      }, 0)
      const expoAfueraCentroAmerica = grupo.reduce((t, item) => {
        t += item.expoAfueraCentroAmerica
        return t
      }, 0)
      const exportacionesServicios = grupo.reduce((t, item) => {
        t += item.exportacionesServicios
        return t
      }, 0)
      const ventasZonasFrancasDPA = grupo.reduce((t, item) => {
        t += item.ventasZonasFrancasDPA
        return t
      }, 0)
      const ventasZonasFrancas = grupo.reduce((t, item) => {
        t += item.ventasZonasFrancas
        return t
      }, 0)
      const total = grupo.reduce((t, item) => {
        t += item.total
        return t
      }, 0)

      return {
        fechaNegociacion: fecha,
        codigo: grupo[0].codigo,
        claseDocumento: grupo[0].claseDocumento,
        numeroResolucion: grupo[0].numeroResolucion,
        serieDocumento: grupo[0].serieDocumento,
        tipoDocumentoCodigo: grupo[0].tipoDocumentoCodigo,
        numeroAnexo: grupo[0].numeroAnexo,
        numDocumentoDel: grupo[0].numDocumentoDel,
        numDocumentoAl: grupo[grupo.length - 1].numDocumentoAl,
        numControlInternoDel: grupo[0].numControlInternoDel,
        numControlInternoAL: grupo[grupo.length - 1].numControlInternoAL,
        subtotal0: formatNumber(subtotal0),
        ventasProporcionalidad: formatNumber(ventasProporcionalidad),
        ventasNoSujetas: formatNumber(ventasNoSujetas),
        subtotalIva: formatNumber(subtotalIva),
        expoDentroCentroAmerica: formatNumber(expoDentroCentroAmerica),
        expoAfueraCentroAmerica: formatNumber(expoAfueraCentroAmerica),
        exportacionesServicios: formatNumber(exportacionesServicios),
        ventasZonasFrancasDPA: formatNumber(ventasZonasFrancasDPA),
        ventasZonasFrancas: formatNumber(ventasZonasFrancas),
        total: formatNumber(total),
      }
    })

    return resultado
  }, [])

  const parserNoContribuyente = useCallback(
    (data) => {
      const d = data.map((venta, i) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          const descuentoGlobal = venta.descuentoGlobal ?? 0
          const itemsSinImpuesto = venta.itemsSinImpuesto ?? 0
          const itemsConImpuesto = venta.itemsConImpuesto ?? 0
          const totalProductos = itemsSinImpuesto + itemsConImpuesto
          const descPorItem = descuentoGlobal / totalProductos
          const totalDescuGravado = itemsConImpuesto * descPorItem
          const totalDescuExento = itemsSinImpuesto * descPorItem
          const totalExenta = utilidades.formatDecimal(
            venta.totalExenta - totalDescuExento,
            2,
          )
          const totalGravada = utilidades.formatDecimal(
            venta.totalGravada - totalDescuGravado,
            2,
          )

          const numeroControl =
            'DTE-' +
            VentaHelperSV.generarCodigoControl(
              venta.tipoDocumentoCodigo,
              venta.establecimiento,
              venta.puntoEmision,
              Number(venta.numero),
              empresa?.comercial,
              localSession?.tipo,
            ).content
          // subtotalIva: totalGravada,

          const subtotalIva = Number(venta?.subtotalIva)

          const date = venta?.fechaNegociacion.toString()
          const codigoGen = venta?.autorizacionHacienda?.codigoGeneracion ?? ''
          return {
            ...venta,
            codigo: i,
            fechaNegociacion: date,
            numeroResolucion: numeroControl,
            serieDocumento: 'N/A',
            numDocumentoDel: codigoGen,
            numDocumentoAl: codigoGen,
            numControlInternoDel: 'N/A',
            numControlInternoAL: 'N/A',
            ventasNoSujetas: 0,
            ventasProporcionalidad: 0,
            numeroAnexo: '2',
            numeroMaquinaRegistradora: '',
            expoDentroCentroAmerica: 0,
            expoAfueraCentroAmerica: 0,
            exportacionesServicios: 0,
            ventasZonasFrancas: 0,
            ventasZonasFrancasDPA: 0,
            subtotal0: totalExenta,
            subtotalIva: subtotalIva,
            claseDocumento: 4,
            claseDocumentoDescripcion:
              '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
      console.log('data con. final', d)
      const agrupados = agruparPorFechaNegociacion(d)
      console.log('agrupados', agrupados)
      return agrupados
    },
    [localSession, empresa, agruparPorFechaNegociacion],
  )

  useEffect(() => {}, [ventasRetenciones])

  const parserExportacion = useCallback(
    (data) => {
      return data.map((venta) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          const descuentoGlobal = venta.descuentoGlobal ?? 0

          const totalGravada = utilidades.formatDecimal(
            venta.totalGravada - descuentoGlobal,
            2,
          )
          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
              '-',
              '',
            ) ?? ''

          return {
            ...venta,
            codigo: venta?.comprobante,
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                venta.tipoDocumentoCodigo,
                venta.establecimiento,
                venta.puntoEmision,
                Number(venta.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            subtotalIva: totalGravada,

            numeroAnexo: '2',
            subtotal0: 0,

            numControldocumentoDel: codigoGen,
            numDocumentoDel: codigoGen,
            numControldocumentoAL: '0',
            ventasNoSujetas: 0,
            ventasProporcionalidad: 0,
            expoDentroCentroAmerica: 0,
            expoAfueraCentroAmerica: 0,
            exportacionesServicios: 0,
            numeroMaquinaRegistradora: '0',
            ventasZonasFrancas: 0,
            ventasZonasFrancasDPA: 0,

            claseDocumento: '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
    },
    [empresa, localSession],
  )

  const parserSujetoExcluido = useCallback(
    async (data) => {
      const tiposIdApi = await tipoIdentificacionService.getTipoIdetificacion(
        TipoTransaccion.compra,
        empresa.codigoPais,
      )
      return data.map((venta) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          let tipoId: TipoIdentificacion | null = null

          if (tiposIdApi.auto && tiposIdApi.auto.length > 0) {
            const selectedIndex = tiposIdApi.auto.findIndex(
              (select: TipoIdentificacion) => {
                return select.codigo == venta.proveedorTipoIdentificacion
              },
            )
            if (selectedIndex > -1) {
              tipoId = tiposIdApi.auto[selectedIndex]
            }
          }

          const descuentoGlobal = venta.descuentoGlobal ?? 0
          const itemsSinImpuesto = venta.itemsSinImpuesto ?? 0
          const itemsConImpuesto = venta.itemsConImpuesto ?? 0
          const totalProductos = itemsSinImpuesto + itemsConImpuesto
          const descPorItem = descuentoGlobal / totalProductos
          const totalDescuGravado = itemsConImpuesto * descPorItem
          const totalDescuExento = itemsSinImpuesto * descPorItem
          const totalExenta = utilidades.formatDecimal(
            venta.totalExenta - totalDescuExento,
            2,
          )
          const totalGravada = utilidades.formatDecimal(
            venta.totalGravada - totalDescuGravado,
            2,
          )

          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
              '-',
              '',
            ) ?? ''

          return {
            ...venta,
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                venta.tipoDocumentoCodigo,
                venta.establecimiento,
                venta.puntoEmision,
                Number(venta.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            subtotalIva: totalGravada,
            tipoDocProveedor: tipoId?.descripcion ?? '',
            numeroAnexo: '5',
            numControldocumentoDel: codigoGen,
            numDocumentoDel: codigoGen,
            clasificacionDescripcion:
              venta?.clasificacionDescripcion != false
                ? venta?.clasificacionDescripcion
                : '',
            sectorDescripcion:
              venta?.sectorDescripcion != false ? venta?.sectorDescripcion : '',
            operacionDescripcion:
              venta.operacionDescripcion != false
                ? venta?.operacionDescripcion
                : '',
            tipoCostoDescripcion:
              venta?.tipoCostoDescripcion != false
                ? venta?.tipoCostoDescripcion
                : '',
            tipoIdentificacion: tipoId?.descripcion ?? '',
            dui:
              tipoId?.codTributario == '13' ? venta.clienteIdentificacion : '',
            nit:
              tipoId?.codTributario == '36' ? venta.clienteIdentificacion : '',

            numControldocumentoAL: '0',
            ventasNoSujetas: 0,
            ventasProporcionalidad: 0,
            expoDentroCentroAmerica: 0,
            expoAfueraCentroAmerica: 0,
            exportacionesServicios: 0,
            numeroMaquinaRegistradora: '0',
            ventasZonasFrancas: 0,
            ventasZonasFrancasDPA: 0,
            retener: venta.retener,

            subtotal0: totalExenta,
            claseDocumento: '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
    },
    [empresa, localSession],
  )

  const parserContribuyente = useCallback(
    (data, iva) => {
      console.log('parserContribuyente', data)
      const d = data.map((venta, i) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          const descuentoGlobal = venta.descuentoGlobal ?? 0
          const itemsSinImpuesto = venta.itemsSinImpuesto ?? 0
          const itemsConImpuesto = venta.itemsConImpuesto ?? 0
          const totalProductos = itemsSinImpuesto + itemsConImpuesto
          const descPorItem = descuentoGlobal / totalProductos
          const totalDescuGravado = itemsConImpuesto * descPorItem
          const totalDescuExento = itemsSinImpuesto * descPorItem
          const totalExenta = utilidades.formatDecimal(
            venta.totalExenta - totalDescuExento,
            2,
          )
          const totalGravada = utilidades.formatDecimal(
            venta.totalGravada - totalDescuGravado,
            2,
          )
          const totalIva = utilidades.formatDecimal(totalGravada * iva, 2)
          const subtotalIva = Number(venta?.subtotalIva)
          const date = venta?.fechaNegociacion.toString()
          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll('-', '')

          const numeroControlDte =
            'DTE-' +
            VentaHelperSV.generarCodigoControl(
              venta.tipoDocumentoCodigo,
              venta.establecimiento,
              venta.puntoEmision,
              Number(venta.numero),
              empresa?.comercial,
              localSession?.tipo,
            ).content

          //    venta?.autorizacionHacienda?.codigoGeneracion,

          return {
            ...venta,
            codigo: i,
            fechaNegociacion: date,
            claseDocumento: 4,
            tipoDocumentoCodigo: venta?.tipoDocumentoCodigo,
            numeroResolucion: numeroControlDte,
            serieDocumento: venta?.autorizacionHacienda?.selloRecibido,
            numDocumento: codigoGen,
            numControlInterno: venta?.comprobante,
            clienteIdentificacion: venta?.clienteIdentificacion,
            clienteNombre: venta?.clienteNombre,
            numDocumentoAl: codigoGen,
            numControlInternoAL: 'N/A',
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                venta.tipoDocumentoCodigo,
                venta.establecimiento,
                venta.puntoEmision,
                Number(venta.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            subtotalIva: formatNumber(subtotalIva),
            debitoFiscal: formatNumber(0),
            subtotal0: formatNumber(totalExenta),
            iva: formatNumber(totalIva),
            total: formatNumber(venta?.total),
            ventasNoSujetas: formatNumber(0),
            ventasProporcionalidad: formatNumber(0),
            numeroAnexo: '1',
            duiCliente: '',
            numControldocumentoDel: codigoGen,
            numControldocumentoAL: '0',
            expoDentroCentroAmerica: formatNumber(0),
            expoAfueraCentroAmerica: formatNumber(0),
            exportacionesServicios: formatNumber(0),
            numeroMaquinaRegistradora: '0',
            ventasZonasFrancas: formatNumber(0),
            ventasZonasFrancasDPA: formatNumber(0),
            // subtotalIva: subtotalIva,
            claseDocumentoDescripcion:
              '4. DOCUMENTO TRIBUTARIO ELECTRÓNICO (DTE)',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
      console.log('data con. final', d)

      return d
    },
    [empresa, localSession],
  )

  const parserRetencion = useCallback(
    async (data) => {
      const tiposIdApi = await tipoIdentificacionService.getTipoIdetificacion(
        TipoTransaccion.compra,
        empresa.codigoPais,
      )

      return data.map((venta) => {
        if (venta.estadoElectronico === 'AUTORIZADO') {
          let tipoId: TipoIdentificacion | null = null

          if (tiposIdApi.auto && tiposIdApi.auto.length > 0) {
            const selectedIndex = tiposIdApi.auto.findIndex(
              (select: TipoIdentificacion) => {
                return select.codigo === venta.tipoIdentificacion
              },
            )
            if (selectedIndex > -1) {
              tipoId = tiposIdApi.auto[selectedIndex]
            }
          }
          const codigoGen =
            venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
              '-',
              '',
            ) ?? ''

          return {
            ...venta,
            numeroControl:
              'DTE-' +
              VentaHelperSV.generarCodigoControl(
                venta.tipoDocumentoCodigo,
                venta.establecimiento,
                venta.puntoEmision,
                Number(venta.numero),
                empresa?.comercial,
                localSession?.tipo,
              ).content,
            numControldocumentoDel: codigoGen,
            numDocumentoDel: codigoGen,
            numControldocumentoAL: '0',
            ventasNoSujetas: 0,
            ventasProporcionalidad: 0,
            expoDentroCentroAmerica: 0,
            expoAfueraCentroAmerica: 0,
            exportacionesServicios: 0,
            numeroMaquinaRegistradora: '0',
            ventasZonasFrancas: 0,
            ventasZonasFrancasDPA: 0,

            tipoIdentificacion: tipoId?.descripcion ?? '',
            dui:
              tipoId?.codTributario == '13' ? venta.clienteIdentificacion : '',
            nit:
              tipoId?.codTributario == '36' ? venta.clienteIdentificacion : '',

            numeroAnexo: '7',
            claseDocumento: '07 COMPROBANTE DE RETENCIÓN',
            autorizacionHacienda: {
              ...venta.autorizacionHacienda,
              codigoGeneracion:
                venta?.autorizacionHacienda?.codigoGeneracion?.replaceAll(
                  '-',
                  '',
                ) ?? '',
            },
          }
        }
      })
    },
    [empresa, localSession],
  )

  function formatNumber(num: number): string {
    return num.toFixed(2)
  }

  useEffect(() => {
    const configVentas = async () => {
      const iva = await VentasService.getIva(
        DateUtils.dateToString(new Date(), formatoFechasApi),
        null,
      )
      // creditoFiscal.concat(notasCredito)
      setVentasCCF(
        parserContribuyente(creditoFiscal.concat(notasCredito), iva / 100),
      )
      setVentasAnexos(parserNoContribuyente(ventas))
      setVentasNotasCredito(parserContribuyente(notasCredito, iva / 100))
      setVentasNotasDebito(parserContribuyente(notasDebito, iva / 100))
      setVentasExportacion(parserExportacion(exportacion))
      setVentasRetenciones(await parserRetencion(retenciones))
      setVentasSujetoExcluido(await parserSujetoExcluido(sujetoExcluido))
    }
    configVentas()
  }, [
    creditoFiscal,
    sujetoExcluido,
    exportacion,
    notasCredito,
    notasDebito,
    parserContribuyente,
    parserExportacion,
    parserNoContribuyente,
    ventas,
    parserSujetoExcluido,
    retenciones,
    parserRetencion,
    empresa.codigoPais,
  ])

  const [ventasContribuyentes, setVentasContribuyentes] = React.useState<
    Array<any> | []
  >([])

  React.useEffect(() => {
    const ventasData = ventas.concat(exportacion)
    setVentasContribuyentes(ventasData)
  }, [ventas, exportacion])

  const [configTabs, setConfigTabs] = React.useState<
    Array<TabConfigAnexo> | []
  >([])

  const onExportCF = React.useCallback(
    async (data) => {
      let dataParser: any = ventas.concat(exportacion)
      //add missing fields
      dataParser = data.map((element) => ({
        ...element,
        montoRetencencion: 0,
        tipoOperacion: '-',
        clasificacion: '-',
        sector: '-',
        tipoCosto: 0,
        tipoDocSv: 0,
      }))

      const resultado = await anexoTransaccionalSVService.generarCsvCF(
        dataParser,
      )

      // Download the CSV file
      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'data.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    [exportacion, ventas],
  )

  const onExportRetencion = React.useCallback(async (data) => {
    const resultado = await anexoTransaccionalSVService.generarCsvRete(
      data as any,
    )

    // Download the CSV file
    const csvData = resultado
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'data.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }, [])

  const onExportContribuyente = React.useCallback(
    async (data) => {
      console.log('onExportContribuyente')
      let dataParser: any = ventas.concat(exportacion)

      console.log('dataParser', dataParser)

      //add missing fields
      dataParser = data.map((element) => ({
        ...element,
        montoRetencencion: 0,
        tipoOperacion: '-',
        clasificacion: '-',
        sector: '-',
        tipoCosto: 0,
        tipoDocSv: 0,
      }))

      console.log('dataParser', dataParser)

      const resultado =
        await anexoTransaccionalSVService.generarCsvContribuyente(dataParser)

      // Download the CSV file
      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'data.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    [exportacion, ventas],
  )

  const onExportCSV = React.useCallback(async (data: ConfigDocCSV) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet(data?.title ?? '')
    exportDataGrid({
      component: data.component,
      worksheet: worksheet,
    }).then(function () {
      worksheet.spliceRows(1, 1) // elimina los header
      // Escribir el contenido en el buffer CSV
      // Configuración para el delimitador de columnas ;
      const csvOptions = {
        formatterOptions: {
          delimiter: ';',
        },
      } as Partial<CsvWriteOptions>
      workbook.csv
        .writeBuffer(csvOptions)
        .then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'text/csv;charset=utf-8;' }),
            data.filename + '.csv',
          )
        })
        .catch((error) => {
          console.error('Error al generar el archivo CSV', error)
        })
    })
  }, [])

  const onExportSujeto = React.useCallback(
    async (data) => {
      const dataParser = ventasSujetoExcluido.map((item) => ({
        ...item,
        clienteNombre: item?.proveedorNombre ?? '',
        clienteIdentificacion: item?.proveedorIdentificacion ?? '',
        tipoDocSv:
          item?.tipoDocProveedor != false ? item?.tipoDocProveedor : '',
        retTotal: item?.retener != false ? item?.retener : '',
        tipoOperacion:
          item?.operacionDescripcion != false ? item?.operacionDescripcion : '',
        clasificacion:
          item?.clasificacionDescripcion != false
            ? item?.clasificacionDescripcion
            : '',
        sector: item?.sectorDescripcion != false ? item?.sectorDescripcion : '',
        tipoCosto:
          item?.tipoCostoDescripcion != false ? item?.tipoCostoDescripcion : '',
      }))

      const resultado = await anexoTransaccionalSVService.generarCsvSujeto(
        dataParser,
      )

      // Download the CSV file
      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'data.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    [ventasSujetoExcluido],
  )

  const onExportContribuyenteNotaCredito = React.useCallback(
    async (data) => {
      console.log('onExportContribuyenteNotaCredito')
      console.log('ventasNotasCredito', ventasNotasCredito)
      let dataParser: any = []

      console.log('dataParser 1', dataParser)

      //add missing fields
      dataParser = ventasNotasCredito.map((element) => ({
        ...element,
        montoRetencencion: 0,
        tipoOperacion: '-',
        clasificacion: '-',
        sector: '-',
        tipoCosto: 0,
        tipoDocSv: 0,
      }))

      console.log('dataParser 2', dataParser)

      const resultado =
        await anexoTransaccionalSVService.generarCsvContribuyente(dataParser)

      // Download the CSV file
      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'data.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    [ventasNotasCredito],
  )

  const onMerge = React.useCallback(async () => {
    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.export,
      }),
    )

    dispatch(
      setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Por favor espere...',
          },
        },
        key: tabId,
      }),
    )

    //validar que si hay datos seleccionados pueda combinar solo factura y exportacion si mostrar un mensaje que diga que no se puede combinar
    if (ventas.length > 0 && exportacion.length > 0) {
      //combinar los datos
      // let data = ventas.concat(exportacion)
      const data = ventas.concat(exportacion).map((det) => {
        return {
          ...det,
          localTipo:
            Number(det?.localTipo) === ETiposEstablecimientosMH.Matriz
              ? 'M'
              : 'S',
        }
      })

      console.log('data to anexo', data)
      const anexo: any = []
      data.map(function (d) {
        if (d?.estadoElectronico === 'AUTORIZADO') {
          anexo.push(d)
        }
      })
      console.log(' anexo', anexo)

      const resultado = await anexoTransaccionalSVService.generarCsvCF(
        anexo as any,
      )
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )

      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'ventasnocontribuyentes.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } else if (
      notasCredito.length > 0 &&
      creditoFiscal.length > 0 &&
      notasDebito.length
    ) {
      //combinar los datos
      let data = notasCredito.concat(creditoFiscal).concat(notasDebito)

      //add missing fields
      data = data.map((element) => ({
        ...element,
        montoRetencencion: 0,
        tipoOperacion: '-',
        clasificacion: '-',
        sector: '-',
        tipoCosto: 0,
        tipoDocSv: 0,
      }))

      const resultado =
        await anexoTransaccionalSVService.generarCsvContribuyente(data as any)

      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )

      const csvData = resultado
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'ventascontribuyentes.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } else {
      //mensaje que diga que no se puede combinar con toast
      dispatch(
        setDatosEdicion({
          data: {
            ...datosEdicion,
            loader: {
              show: false,
              mensaje: '',
            },
          },
          key: tabId,
        }),
      )

      dispatch(
        addToast({
          content:
            'No se puede combinar los datos seleccionados, solo puede combinar Facturas y Facturas de Exportación o Notas de Crédito, Notas de Débito y Crédito Fiscal',
          type: ToastTypes.Danger,
        }),
      )
    }

    dispatch(
      setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined,
      }),
    )
  }, [
    creditoFiscal,
    datosEdicion,
    dispatch,
    exportacion,
    notasCredito,
    notasDebito,
    tabId,
    ventas,
  ])

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }

      const data = { ...datosEdicion }
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...AnexoButtons,
            Buscar: false,
            Editar: false,
            Imprimir: false,
            Exportar: true,
            Enviar: false,
            Guardar: true,
            Generar_xml: false,
          },
        }),
      )

      let tabs: TabConfigAnexo[] = []

      tabs = dteSalvador.map(function (d) {
        console.log('d.codigo', d.codigo)
        let codeTipoDte = null
        if (d?.codigo === 'ANULADOS') {
          codeTipoDte = 'ANULADOS'
        } else {
          codeTipoDte = d.codigo as TiposComprobantesSLV
        }
        return {
          visible: false,
          title: d.valor,
          code: codeTipoDte,
        }
      })

      if (ventas.length > 0) {
        const findCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Factura,
        )
        if (findCF > -1) {
          tabs[findCF].visible = true
        }
      }

      if (creditoFiscal.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.CreditoFiscal,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (creditoFiscal.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.CreditoFiscal,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (exportacion.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.FacturaExportacion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (sujetoExcluido.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.FacturaExcluidoSujeto,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (notasCredito.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.NotaCredito,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (notasDebito.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.NotaDebito,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (notasRemision.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.NotaRemision,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (liquidaciones.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Liquidacion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (liquidacionesContables.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.LiquidacionContable,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (donaciones.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Donacion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (retenciones.length > 0) {
        const findCCF = tabs.findIndex(
          (f) => f.code === TiposComprobantesSLV.Retencion,
        )
        if (findCCF > -1) {
          tabs[findCCF].visible = true
        }
      }

      if (anulados.length > 0) {
        const indexAnulados = tabs.findIndex(
          (f) => f.code === 'ANULADOS',
        )
        if (indexAnulados > -1) {
          tabs[indexAnulados].visible = true
        }
      }

      setConfigTabs(tabs)

      if (tabs.length > 0) {
        const i = tabs.findIndex((objeto) => objeto.visible === true)
        if (i > -1) {
          setActiveKey(tabs[i].code as TiposComprobantesSLV)
        }
      }

      try {
        data.loading = false
        data.loader = {
          mensaje: '',
          show: false,
        }
      } catch (error) {
        data.loading = false
        data.tieneError = true
        data.mensajeError =
          typeof error == 'string' ? error : JSON.stringify(error)
        data.loader = {
          mensaje: '',
          show: false,
        }
        dispatch(
          addToast({
            content: data.mensajeError,
            type: ToastTypes.Danger,
          }),
        )
      }
      dispatch(
        setDatosEdicion({
          data: data,
          key: tabId,
        }),
      )
    },
    [
      loading,
      datosEdicion,
      dispatch,
      tabId,
      ventas,
      creditoFiscal,
      exportacion,
      sujetoExcluido,
      notasCredito,
      notasDebito,
      notasRemision,
      liquidaciones,
      liquidacionesContables,
      donaciones,
      retenciones,
      anulados,
    ],
  )

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px' }}>{tittle}</strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item?.sms ?? ''}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])

  const validarFormulario = React.useCallback(async () => {
    const smsErrors: Array<any> = []
    // if (datosEdicion?.cliente?.codigo === 0) {
    //   let sms: string = "Seleccione el Cliente por favor.";
    //   smsErrors.push({ sms });
    // }
    return smsErrors
  }, [])

  const generar = React.useCallback(async () => {
    if (usuario == null || empresa == null || localSession == null) {
      return
    }

    const resValidarFormulario = await validarFormulario()
    if (resValidarFormulario.length > 0) {
      onErrorConfirm(
        resValidarFormulario,
        'Antes de continuar revise lo siguiente:',
      )
      return false
    }

    // try {
    //   dispatch(
    //     setDatosEdicion({
    //       data: {
    //         ...datosEdicion,
    //         loader: {
    //           show: true,
    //           mensaje: 'Generando...',
    //         },
    //       },
    //       key: tabId,
    //     }),
    //   )

    //   dispatch(
    //     setCurrentExecutingAction({
    //       tabKey: tabId,
    //       buttonType: ButtonTypes.save,
    //     }),
    //   )

    //   const generaAnexo: TAnexoIngresar = {
    //     compras: compras,
    //     retenciones: retencionesRedux,
    //     detalleRetenciones: detalleRetencionesRedux,
    //     ventas: ventas,
    //     anuladas: anulados,
    //     ventasEstablecimiento: ventasEstablecimiento,
    //   }

    //   const resultado = await anexoTransaccionalSVService.ingresarAnexo(
    //     generaAnexo,
    //   )

    //   let sms = ''
    //   sms = resultado.message
    //   let typeToast = 'danger'

    //   if (resultado['error'] === true) {
    //     typeToast = 'warning'
    //     setToast(sms, typeToast, 'Guardar')
    //     dispatch(
    //       setDatosEdicion({
    //         data: {
    //           ...datosEdicion,
    //           loader: {
    //             show: false,
    //             mensaje: '',
    //           },
    //           // imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
    //         },
    //         key: tabId,
    //       }),
    //     )
    //     dispatch(
    //       setCurrentExecutingAction({
    //         tabKey: tabId,
    //         buttonType: undefined,
    //       }),
    //     )
    //     return false
    //   } else {
    //     typeToast = 'success'
    //     setToast(sms, typeToast, 'Guardar')
    //   }

    //   dispatch(
    //     setDatosEdicion({
    //       data: {
    //         ...datosEdicion,
    //         ruc: ruc,
    //         compras: compras,
    //         retenciones: retencionesRedux,
    //         detalleRetenciones: detalleRetencionesRedux,
    //         consumidorFinal: ventas,
    //         anulados: anulados,
    //         ventasEstablecimiento: ventasEstablecimiento,
    //         loader: {
    //           show: false,
    //           mensaje: '',
    //         },
    //         imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false,
    //       },
    //       key: tabId,
    //     }),
    //   )

    //   // dispatch(changeEditStatus({
    //   //   estado: StatesEdition.save,
    //   //   tabKey: tabId,
    //   //   info: {
    //   //     // ...resultado['auto'],
    //   //     codigo: 1,
    //   //     numero: 1,
    //   //     titleTab: "AT-" + String(generaAnexo.mes) + String(generaAnexo.anio)
    //   //   },
    //   //   buttons: {
    //   //     ...AnexoButtons,
    //   //     Buscar: true,
    //   //     Generar_xml: true,
    //   //   }
    //   // }));

    //   // if (empresa.imprimeAutomaticamenteAlfacturar === true) {
    //   await cargarXmlLegacy()
    //   // }

    //   dispatch(
    //     setCurrentExecutingAction({
    //       tabKey: tabId,
    //       buttonType: undefined,
    //     }),
    //   )
    // } catch (error) {
    //   console.error(error)
    //   const err: any = error
    //   let message = ''
    //   if (err && err['error'] === true) {
    //     message = err['message']
    //   } else {
    //     message =
    //       'Error al obtener el anexo ' +
    //       (typeof err === 'string' ? err : JSON.stringify(err))
    //   }
    //   dispatch(
    //     addToast({
    //       id: '',
    //       autoHide: 5000,
    //       content: message,
    //       fade: true,
    //       title: 'Guardar',
    //       type: ToastTypes.Danger,
    //     }),
    //   )
    //   dispatch(
    //     setDatosEdicion({
    //       data: {
    //         ...datosEdicion,
    //         loader: {
    //           show: false,
    //           mensaje: '',
    //         },
    //       },
    //       key: tabId,
    //     }),
    //   )
    //   dispatch(
    //     setCurrentExecutingAction({
    //       tabKey: tabId,
    //       buttonType: undefined,
    //     }),
    //   )
    //   return
    // }
  }, [empresa, localSession, onErrorConfirm, usuario, validarFormulario])

  const generarCsv = React.useCallback(async () => {
    console.log('generarCsv')
    console.log({ datosEdicion })
    onMerge()
  }, [datosEdicion, onMerge])

  const generateBooks = React.useCallback(async () => {
    console.log('generateBooks')
    const dataParser: any = []
    const fac_ = ventas.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...fac_)

    const exp_ = exportacion.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...exp_)

    const ccf_ = creditoFiscal.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...ccf_)

    const nc_ = notasCredito.map((e) => ({
      ...e,
      facturasEmitidas: 0,
      fechaEmision: e?.fecha ?? '',
      numerofactura: e?.numero ?? '',
      tipoComprobante: e?.tipoDocumentoDescripcion ?? '',
      clienteNombre: e?.clienteNombre ?? '',
      descripcionOperacion: e?.observaciones ?? '',
      baseImponible: e?.subtotal ?? 0,
      tipoIva: e?.iva > 0 ? 13 : 'Exento',
      importeIva: e?.iva ?? 0,
      totalFacturado: e?.total ?? 0,
    }))

    dataParser.push(...nc_)
    console.error('dataParser', dataParser)

    try {
      const response = await ExportarExcelService.exportarArchivo(dataParser)
      const filename = 'libroVentas.xlsx'
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    } catch (error) {
      dispatch(
        addToast({
          title: 'Exportar Excel',
          content: typeof error == 'string' ? error : JSON.stringify(error),
          type: ToastTypes.Danger,
          autoHide: 3000,
        }),
      )
    }
  }, [dispatch, ventas, creditoFiscal, exportacion, notasCredito])

  const handleButtonClick = React.useCallback(
    (button: ButtonTypes) => {
      switch (button) {
        case ButtonTypes.generar_xml.replaceAll('_', ' '): {
          generar()
          break
        }
        case ButtonTypes.export:
          generarCsv()
          break
        case ButtonTypes.generateBooks:
          generateBooks()
          break
        case ButtonTypes.new:
          // close tab

          dispatch(closeTab(tabId))

          setTimeout(() => {
            const idTab: any = tabId
            dispatch(clearDatosEdicion(idTab))
          }, 100)

          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, generar, generarCsv, generateBooks, tabId],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  return (
    <>
      <div
        style={{ padding: '10px', overflowX: 'hidden', paddingBottom: '150px' }}
        id="mantenimientoPedido"
      >
        <Dialog ref={dialogRef} />
        {/* {showVisor && (
          <VisorXml
            show={showVisor}
            fileName={`${filename ?? ''}`}
            urlXml={paths?.xml ?? null}
            urlBlobPdf={paths?.urlBlobPdf ?? null}
            onClose={() => {
              setShowVisor(false)
              setFilename('')
            }}
          />
        )} */}

        <BlockUi
          loader={LoadingIndicator}
          tag="div"
          blocking={loader.show}
          message={loader.mensaje}
        >
          <fieldset>
            <ValidationGroup
              id={`valGroupOrden${props.tabId}`}
              ref={validationGroupRef}
            >
              <div>
                <RowContainer>
                  <CustomCol>
                    <CNav variant="tabs" role="tablist">
                      {configTabs.map(
                        (d, i) =>
                          d.visible && (
                            <CNavItem key={i}>
                              <CNavLink
                                active={activeKey === d.code}
                                onClick={() => setActiveKey(d.code)}
                              >
                                {d.title}
                              </CNavLink>
                            </CNavItem>
                          ),
                      )}
                    </CNav>

                    <CTabContent style={{ overflowY: 'unset' }}>
                      {configTabs.map((d, i) => (
                        <CTabPane key={i} visible={activeKey === d.code}>
                          <CCard className={'m-0 p-4'}>
                            <RowContainer>
                              <CustomCol>
                                <strong>{d?.title ?? ''}</strong>
                              </CustomCol>
                              <CustomCol></CustomCol>
                            </RowContainer>
                            <RowContainer>
                              <CustomCol>
                                {d.code === TiposComprobantesSLV.Factura && (
                                  <TablaVentas
                                    data={ventasAnexos}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code === TiposComprobantesSLV.Retencion && (
                                  <TablaRetenciones
                                    data={ventasRetenciones}
                                    onExport={onExportRetencion}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.CreditoFiscal && (
                                  <TablaCCF
                                    data={ventasCCF}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.FacturaExportacion && (
                                  <TablaVentas
                                    data={ventasExportacion}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.FacturaExcluidoSujeto && (
                                  <TablaSuj
                                    data={ventasSujetoExcluido}
                                    onExport={onExportSujeto}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.NotaCredito && (
                                  <TablaNotasCredito
                                    data={ventasNotasCredito}
                                    onExport={onExportCSV}
                                  />
                                )}
                                {d.code === TiposComprobantesSLV.NotaDebito && (
                                  <TablaCCF
                                    data={ventasNotasDebito}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.NotaRemision && (
                                  <TablaCCF
                                    data={notasRemision}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.Liquidacion && (
                                  <TablaCCF
                                    data={liquidaciones}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code ===
                                  TiposComprobantesSLV.LiquidacionContable && (
                                  <TablaCCF
                                    data={liquidacionesContables}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code === TiposComprobantesSLV.Donacion && (
                                  <TablaCCF
                                    data={donaciones}
                                    onExport={onExportContribuyente}
                                  />
                                )}
                                {d.code === 'ANULADOS' && (
                                  <TablaAnulados
                                    data={anulados}
                                    onExport={onExportCSV}
                                  />
                                )}
                              </CustomCol>
                            </RowContainer>
                          </CCard>
                        </CTabPane>
                      ))}
                    </CTabContent>
                  </CustomCol>
                </RowContainer>
              </div>
            </ValidationGroup>
          </fieldset>
        </BlockUi>
      </div>
    </>
  )
}

export const AnexoButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: true,
  Extraer: false,
  Generar_xml: false,
}

export default React.memo(Anexo)
